<section class="complete-section">
  <div class="header">
    <p class="heading" *ngIf="!listItemdata.buttonData">Item</p>
    <p class="heading" *ngIf="listItemdata.buttonData">Button settings</p>
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>

  <div class="content mt-15">
    <div *ngIf="data.hasOwnProperty('image')">
      <div class="d-flex justify-space-between">
        <p class="heading mb-0">Image</p>
        <span class="regerateContent mt-1" (click)="getUnsplaceImage()">
          <i class="fa fa-bolt" *ngIf="!regerateLoading"></i>
          <i class="fa fa-spinner fa-spin" *ngIf="regerateLoading"></i>
          Regenerate
        </span>
      </div>
      <app-image-editor [imageData]="data.image" [sectionId]="sectionId" [iconData]="data.icon" [showImage]="showImage"></app-image-editor>
      <hr class="line">
    </div>

    <ng-container *ngIf="data.hasOwnProperty('inputText')">
      <div class="mt-15" *ngFor="let inputText of data.inputText">
        <div class="d-flex w-100 justify-space-between">
          <label class="input-label">{{inputText.label}}</label>
          <span (click)="regerateContent(inputText)" class="regerateContent" *ngIf="inputText.label != 'Step'">
            <i class="fa fa-bolt" *ngIf="loading != inputText.label"></i>
            <i class="fa fa-spinner fa-spin" *ngIf="loading == inputText.label"></i>
            Regenerate
          </span>
        </div>
        <div class="input-text" *ngIf="!inputText.isRTE">
          <input type="text" [(ngModel)]="inputText.value">
        </div>
        <div *ngIf="inputText.isRTE">
          <quill-editor [(ngModel)]="inputText.value" [modules]="quillConfig" (onEditorCreated)="onEditorCreated($event,inputText.value)"></quill-editor>
        </div>
      </div>
      <hr class="line">
    </ng-container>

    <ng-container *ngIf="data.hasOwnProperty('videoLink')">
      <label class="input-label">Video</label>
      <div class="input-text">
        <input type="text" [(ngModel)]="data.videoLink.url">
      </div>
    </ng-container>

    <!-- button is the key for button in list item data -->
    <div class="mt-15" *ngIf="data.hasOwnProperty('button')">
      <label class="input-label">Button</label>
      <app-button-editor [buttonData]="data.button" [templatePage]="templatePage" [currentPageIndex]="currentPageIndex"></app-button-editor>
      <hr class="line">
    </div>

    <!-- if we have list of button in component itself -->
    <div class="mt-15" *ngIf="listItemdata.buttonData && data.hasOwnProperty('content')">
      <label class="input-label">Button</label>
      <app-button-editor [buttonData]="data.content" [templatePage]="templatePage" [currentPageIndex]="currentPageIndex"></app-button-editor>
      <hr class="line">
    </div>

    <ng-container  *ngIf="data.hasOwnProperty('highlight')">
      <div class="display-flex justify-space mt-15 align-center">
        <div>
          <p class="heading mb-5">Highlight</p>
          <span class="desc">Make this tier stand out to encourage customers to select it.</span>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="data.highlight.highlighted"
            role="switch">
        </div>
      </div>
      <ng-container *ngIf="data.highlight.highlighted">
        <label class="input-label mt-15">Label</label>
        <div class="input-text">
          <input type="text" [(ngModel)]="data.highlight.label">
        </div>
      </ng-container>
      <hr class="line">
    </ng-container>

    <div *ngIf="data.hasOwnProperty('additionalData')">
      <p class="heading mb-0">What's included</p>
      <div *ngFor="let item of data.additionalData;let i = index">
        <div class="single-item">
          <div style="line-height: 18px;">
            <p class="item-heading">{{item.content}}</p>
          </div>
          <div style="margin-top: auto; margin-bottom: auto;">
            <mat-icon style="color: #0099CC;cursor: pointer;" (click)="editAdditionalData(item)">edit</mat-icon>
            <mat-icon style="color: red;cursor: pointer;">delete</mat-icon>
          </div>
        </div>
      </div>
      <div class="add-list-item" (click)="addAdditionalData()">
        <div class="add-item">Add item <mat-icon class="fs-21">add</mat-icon></div>
      </div>
    </div>

    <div *ngIf="!listItemdata.buttonData && data.hasOwnProperty('content')">
      <label class="input-label">Content</label>
      <div class="input-text">
        <textarea [(ngModel)]="data.content" rows="4" cols="10"></textarea>
      </div>
    </div>
  </div>

  <div class="Footer" *ngIf="listItemdata.newItem && !listItemdata.buttonData">
    <button class="btn" (click)="addNewItem()">Add Data</button>
  </div>
</section>
