<!-- <section lottie [options]="options" (animationCreated)="animationCreated($event)"></section> -->
<!-- <div style="    position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
    <img src="./../../../../../assets/animations/loader_logo.svg" alt="">
</div>
<ng-lottie [options]="options" (animationCreated)="animationCreated($event)" style="display:flex;margin:0 auto;width:16%" class="anime_loader">
</ng-lottie> -->

<div style="position: relative; width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
    <ng-lottie [options]="options" (animationCreated)="animationCreated($event)" style="width: 16%; height: auto;">
    </ng-lottie>
    <img src="./../../../../../assets/animations/loader_logo.svg" alt="" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 2%;">
  </div>