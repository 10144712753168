import { AlignContent, Corners, FooterType, FooterTypes, HeaderButtonStyle, ImageFit, ImageRatio, TextPosition, logoDirection, logoSpeed, logoType, showCaseType } from "simpo-component-library";
import { CARD_SIZE, FIT, MAP_STYLE, SPACING_TYPE } from "simpo-component-library/lib/styles/types";


interface genericType<T> {
  type: T;
}
interface type {
  type: string;
}

export const imageFit: Array<type> = [
  {
    type: ImageFit.Cover
  }
  ,
  {
    type: ImageFit.Contain
  }
];

interface ratioType {
  key: string;
  value: string;
}
export const aspectRatio: Array<ratioType> = [
  {
    key : 'square',
    value: ImageRatio.SQUARE
  },
  {
    key : 'portrait',
    value: ImageRatio.PORTRAIT
  },
  {
    key : 'landscape',
    value: ImageRatio.LANDSCAPE
  },
  {
    key : 'widescreen',
    value: ImageRatio.WIDESCREEN
  }
]

export const corners: Array<type> = [
  {
    type: Corners.Small
  },
  {
    type: Corners.Medium
  },
  {
    type: Corners.Large
  },
  {
    type: Corners.None
  }
]


export const cardSize: Array<genericType<CARD_SIZE>> = [
  {
    type: 'Small'
  },
  {
    type: 'Medium'
  },
  {
    type: 'Large'
  }
]

export const textPosition: Array<type> = [
  {
    type: TextPosition.BELOW_IMAGE
  },
  {
    type: TextPosition.TOP_OF_IMAGE
  },
  {
    type: TextPosition.COVERING_IMAGE
  }
]

export const animationType: Array<type> = [
  { type: 'none' },
  { type: 'left' },
  { type: 'top' },
  { type: 'right' },
  { type: 'bottom' },
  { type: 'fadeIn' },
  { type: 'zoom' },
]

export const animationSpeed: Array<type> = [
  { type: 'slow'},
  { type: 'medium'},
  { type: 'fast'},
];

export const productCardTheme: Array<type> = [
  { type: 'Theme1'},
  { type: 'Theme2'}
];

export const spacingType: Array<genericType<SPACING_TYPE>> = [
  {type : 'small'},
  {type : 'medium'},
  {type : 'large'},
  {type : 'none'},
  {type: 'remove'}
]

export const minHeight: Array<genericType<FIT>> = [
  {
    type: 'content'
  },
  {
    type: 'screen'
  }
]
export const flexWrapOptions: string[] = ["ROW", "COLUMN"];
export const headerStyling: string[] = ["Header1", "Header2", "Header3", "Header4"];

export const borderType: Array<type> = [
  {type: 'DASHED'},
  {type: 'SOLID'}
]

export const contentAlignment: Array<type> = [
  {
    type: AlignContent.Image_TOP
  },
  {
    type: AlignContent.Image_CENTER
  },
  {
    type: AlignContent.Image_BOTTOM
  }
]

export const footerType: Array<type> = [
  {
    type: FooterType.FOOTER_1,
  },
  {
    type: FooterType.FOOTER_2
  },
  {
    type: FooterType.FOOTER_3
  },
  {
    type: FooterType.FOOTER_4
  },
  // {
  //   type: FooterTypes.STACKED_DETAILS_CENTER
  // },
  // {
  //   type: FooterTypes.STACKED_DETAILS_LEFT
  // },
  // {
  //   type: FooterTypes.STACKED_DETAILS_RIGHT
  // }
]

export const navigationStyle: Array<type> = [
  {
    type: HeaderButtonStyle.ROUND_BOLD
  },
  {
    type: HeaderButtonStyle.ROUND_SUBTLE
  },
  {
    type: HeaderButtonStyle.SQUARE_BOLD
  },
  {
    type: HeaderButtonStyle.SQUARE_SUBTLE
  },
  {
    type: HeaderButtonStyle.TAB
  }
]

export const mapStyle: Array<genericType<MAP_STYLE>> = [
  {
    type : "Dark"
  },
  {
    type : 'Light'
  },
  {
    type : 'Outdoors'
  },
  {
    type : 'Satellite'
  },
  {
    type : 'Satellite_street'
  },
  {
    type : 'Streets'
  },
  {
    type : 'navigation_day'
  },
  {
    type : 'navigation_night'
  }
]

export const showCaseTypeArray: Array<type> = [
    {
      type : showCaseType.Carousal
    },
    {
      type : showCaseType.Grid
    }
]

export const carouselSpeed: Array<type> = [
  {
    type : "Normal"
  },
  {
    type : "Slow"
  },
  {
    type : "Fast"
  },
  {
    type : 'VeryFast'
  }
]

export const logoCarouselDirection: Array<type> = [
  {
    type: logoDirection.Left
  },
  {
    type: logoDirection.Right
  }
]

export const logoBoxType: Array<type> = [
  {
    type : logoType.Basic
  },
  {
    type : logoType.Boxed
  }
]

export const OverLay: Array<type> = [
  {
    type: 'NONE'
  },
  {
    type: 'LIGHT'
  },
  {
    type: 'MODERATE'
  },
  {
    type: 'STRONG'
  },
  {
    type: 'VERY_STRONG'
  }
]
