import { Component, Input, ViewChild, ViewContainerRef, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ALIGN, Corners, EventsService, FooterTypes, ImageRatio, TextPosition, HeaderButtonStyle, SPACING_TYPE, SPACING } from 'simpo-component-library';
import { EventEmmiterService } from 'simpo-components';
import { V3AddSectionComponent } from 'src/app/master-config-components/micro-apps/invoice/popups/v3-add-section/v3-add-section.component';
import { TemplateService } from 'src/app/services/template.service';
import { WebsiteGenerationService } from 'src/app/website-generation/website-generation.service';
import { PageData, Section } from '../editor.modal';
import { WarningPopupComponent } from '../popups/warning-popup/warning-popup.component';
import { EditorEventService } from 'src/app/services/editor-event.service';
import { EcommerceService } from 'src/app/services/ecommerce.service';
import { toOrdinalWord } from 'src/app/utils/ordinal.util';
import { CrmServiceService } from 'src/app/master-config-components/micro-apps/crm/service/crm-service.service';
import { DbService } from 'src/app/services/db-service/db.service';

@Component({
  selector: 'app-v3-editor',
  templateUrl: './v3-editor.component.html',
  styleUrls: ['./v3-editor.component.scss', './mq-v3-editor.component.scss']
})
export class V3EditorComponent implements OnChanges {

  @ViewChild('displayWebsite', { read: ViewContainerRef })
  displayWebsite: ViewContainerRef | any;
  openDelete: any;
  isEducationalSystem: boolean = false;

  constructor(private templateService: TemplateService,
    private eventHelper: EventEmmiterService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private websiteGenerationService: WebsiteGenerationService,
    private eventDirective: EventsService,
    private eventService: EditorEventService,
    private ecomService: EcommerceService,
    private crmService: CrmServiceService,
    private dbService: DbService
  ) {
    if (window.localStorage.getItem('login_type') === 'EDUCATION_SYSTEM') {
      this.isEducationalSystem = true
    }
    this.getScreenSize()
  }

  businessDetails: any;
  @Input() templatePage: any[] = []
  @Input() currentPageId?: string;
  @Output() websiteLoaderfun: EventEmitter<boolean> = new EventEmitter();
  @Input() themes: any[] = [];

  currentPageIndex = 0;
  subscriptionList: any[] = [];
  showEditor: boolean = false;
  collpaseEditor: boolean = true;
  editorDirection: any;
  sectionName: any;
  screenWidth: any;
  showEditorSectionSubscribe: any;
  carouselSingleBanner: any;
  multiTabEditor: boolean = false;
  singleBannerEditor: boolean = false;
  @HostListener('window:resize', ['$event'])
  toOrdinalWord: any = toOrdinalWord;
  getScreenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  addContactItemSubscription : any;
  isRequiredItemSubscription : any;
  createNewFieldUpdateInForm : any;

  ngOnInit() {
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.templateId = this.businessDetails.templateId;
    }
    if (this.templatePage.length > 0) {
      this.getWebsite()
    }
    this.enumValue();
    this.addPageEvent()
    this.showEditorSection();
    this.openCarouselSingleBanner();
    this.addOrRemoveItem();
    this.createNewFieldUpdateInform();
  }

  createNewFieldUpdateInform(){
    this.createNewFieldUpdateInForm = this.crmService.createFieldInForms.subscribe(
      (res:any) =>{
        if(res.createFrom === 'WEBSITE_EDITOR'){
          this.getAllShowInFormFields(); 
        }
      }
    )
  }

  upadteIsRequired() {
    this.isRequiredItemSubscription = this.crmService.updateIsRequiredInWebForm.subscribe(
      (res: any) => {
        if (res.isRequered) {
          let data = {
            id: res.value.fieldId,
            isRequired: res.status
          }
          this.crmService.updateIsRequiredInWebFormApi(data).subscribe(
            (res: any) => {
              this.crmService.openSnack(
                `${res.data}`,
                'Ok'
              );
              this.getAllShowInFormFields();
            },
            (err) => [
              this.crmService.openSnack(
                `${err.error.message}`,
                'Close'
              )
            ]
          )
        }
      }
    )
  }

  addOrRemoveItem(){
    this.addContactItemSubscription = this.crmService.addOrRemoveItemFromWebForm.subscribe(
      (res: any) => {
        if (res.updateItem) {
          let data = {
            id: res.value.fieldId,
            showInEnquiry: res.status
          }
          this.crmService.updateItemInWebForm(data).subscribe(
            (res: any) => {
              this.crmService.openSnack(
                `${res.data}`,
                'Ok'
              );
              // this.displayWebsite.clear();
              this.getAllShowInFormFields()
            },
            (err) => {
              this.crmService.openSnack(
                `${err.error.message}`,
                'Close'
              )
            }
          )
        }
      }
    )
  }

  convertToWebForm: any = [];
  getAllShowInFormFields() {
    this.crmService.getFieldsShowInForm(this.dbService.getBusinessId(), 'ENQUIRY').subscribe(
      (res: any) => {
        this.convertToWebForm = [];
        const contactUsComponent: any = this.templatePage[this.currentPageIndex].components.find((compos: any) => compos.sectionType.replace(/\s+/g, '_').toLowerCase().includes('contact_us'));
        contactUsComponent.content.contactField.fields = [];
        res.data.data.forEach(element => {
          if (element.showInForm && element.showInForm.includes("ENQUIRY")) {
            if (contactUsComponent) {
              if (element.dataType.toLowerCase() === 'dropdown') {
                if (element.sourceData != null) {
                  if (Object.keys(element?.sourceData).length != 0) {
                    contactUsComponent.content.contactField.fields.push({
                      label: element.fieldLabel,
                      required: element.required,
                      type: element.dataType.toLowerCase(),
                      value: element.fieldLabel,
                      options: element?.sourceData && Object.keys(element?.sourceData).length > 0
                        ? element.sourceData[(element.dataSourceType).toUpperCase()].map(item => ({
                          value: item.name,
                          label: item.name
                        }))
                        : []
                    });
                  }
                  else {
                    contactUsComponent.content.contactField.fields.push({
                      label: element.fieldLabel,
                      required: element.required,
                      type: element.dataType.toLowerCase(),
                      value: element.fieldLabel,
                      options: element.value
                    });
                  }
                }
                else {
                  contactUsComponent.content.contactField.fields.push({
                    label: element.fieldLabel,
                    required: element.required,
                    type: element.dataType.toLowerCase(),
                    value: element.fieldLabel,
                    options: element.value
                  });
                }
              }
              else {
                contactUsComponent.content.contactField.fields.push({ label: element.fieldLabel, required: element.required, type: element.dataType.toLowerCase(), value: element.fieldLabel });
              }
            }
          }
        });
      },
      (err) => {
        this.crmService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.changePageEvent();
  }

  singleItem: any;
  openCarouselSingleBanner() {
    this.carouselSingleBanner = this.eventService.openMultiBannerCarousel.subscribe(
      (res: any) => {
        console.log(res)
        if (res.open) {
          // this.data = res.data;
          // console.log(res)
          // this.singleItem = res.data
          this.singleBannerEditor = true;
          setTimeout(() => {
            this.eventHelper.editSection.emit({ data: res.data });
          }, 300)
        }
      }
    )
  }

  showEditorSection() {
    this.showEditorSectionSubscribe = this.eventService.showEditor.subscribe(
      (res: any) => {
        // console.log(res)
        this.showEditor = res.data;
      }
    )
  }

  ngOnDestroy() {
    this.changePageSubscription?.unsubscribe()
    this.addPageSubscription?.unsubscribe()
    this.showEditorSectionSubscribe?.unsubscribe();
    this.subscriptionList?.forEach(element => {
      element?.unsubscribe();
    });
    this.carouselSingleBanner.unsubscribe();
    this.addContactItemSubscription.unsubscribe();
    if(this.isRequiredItemSubscription){
      this.isRequiredItemSubscription.unsubscribe();
    }
    if(this.createNewFieldUpdateInForm){
      this.createNewFieldUpdateInForm.unsubscribe();
    }
  }

  changePageSubscription?: Subscription;
  changePageEvent() {
    this.changePage()
  }

  addPageSubscription?: Subscription;
  addPageEvent() {
    this.addPageSubscription?.unsubscribe()
    this.addPageSubscription = this.eventService.addPageEvent.subscribe((res: any) => {
      this.createNewPage()
    })
  }

  private templateId: string | null = null;
  getWebsite() {
    // this.currentPageIndex = 0;
    // setTimeout(() => {
    //   const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, this.templatePage[0].components, true)
    //   this.initiateSubscription(instance);
    //   this.websiteLoaderfun.emit(false);
    // }, 500);
    let data = {}
    this.getComponentData(this.templatePage[0].components, 0, data);
  }


  getComponentData(sections: any[], index: number, data: any) {
    data['subIndustryId'] = this.businessDetails?.subIndustryId;
    data['businessId'] = localStorage.getItem('businessId');
    const promises = sections.map(async (section, index) => {
      if (section.type === 'E_COMMERCE') {
        data['sectionType'] = section.sectionType;
        data['collectionId'] = section?.content?.collectionId ? section?.content?.collectionId : '';
        data['productId'] = '';
        section['responseData'] = await this.ecomService.getData(data);
        console.log(data);
      }
    });

    Promise.all(promises).then(() => {
      this.currentPageIndex = index;
      setTimeout(() => {
        const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, sections, true)
        this.initiateSubscription(instance);
        this.websiteLoaderfun.emit(false);
      }, 500);
    });
  }

  sectionData: any;

  initiateSubscription(instance: any) {
    this.subscriptionList.forEach(element => {
      element.unsubscribe();
    });

    this.subscriptionList.push(
      instance.addNewSectionClick.subscribe((res: any) => {
        console.log(res)
        this.addNewSection(res);
      })
    )
    this.subscriptionList.push(
      instance.editSection.subscribe((res: any) => {
        if (res.data.sectionType.includes('carouselBanner')) {
          this.multiTabEditor = true;
          this.showEditor = false;
          // setTimeout(() => {
          //   this.sectionName = res.data.sectionName;
          //   this.eventHelper.editSection.emit({ data: res.data });
          // }, 200)
          this.sectionData = res.data
        }
        else {
          this.showEditor = true;
          this.multiTabEditor = false;
          setTimeout(() => {
            this.sectionName = res.data.sectionName;
            this.eventHelper.editSection.emit({ data: res.data });
          }, 200)
        }
      })
    )
    this.subscriptionList.push(
      instance.restyleSection.subscribe((res: any) => {
        this.regenerateComponent(res);
      })
    )
    this.subscriptionList.push(
      instance.changeContent.subscribe((res: any) => {
        this.regenerateContent(res);
      })
    )
    this.subscriptionList.push(
      instance.changePosition.subscribe((res: any) => {
        this.changeSectionPosition(res);
      })
    )
    this.subscriptionList.push(
      instance.delete.subscribe((res: any) => {
        this.deleteConfirm(res);
      })
    )
    this.subscriptionList.push(
      instance.duplicate.subscribe((res: any) => {
        this.createDuplicateSection(res);
      })
    )

    this.eventDirective.showLoadingScreen.emit(false);
  }

  addNewSection(response: any) {
    if (this.screenWidth > 475) {
      console.log(response)
      // let sectionWindow = this.templateService.openDialog(V3AddSectionComponent, '90%', '50%', null);

      let index: number = response.position === 'ABOVE' ? response.index : response.index + 1;
          this.AddSkeletonSection(index, response.sectionType);

          this.templateService.getSectionForIndustry(response.componentId, this.businessDetails.subIndustryId, this.businessDetails.name, this.businessDetails.id).subscribe((component: any) => {

            let componentStyle = this.getThemeStyle();
            if (component?.data?.styles) {
              if (component.data.styles.background) {
                component.data.styles.background.color = componentStyle.bgColor;
                component.data.styles.background.accentColor = componentStyle.accentColor;
              }
            }

            // TO CHECK IF ADD SECTION COMPONENT IS PRESENT IN PAGE OR NOT IF YES THEN REMOVING IT
            if ((this.checkCurrentPageIsHomePage() &&
              this.templatePage[0].components[1].sectionType == 'addNewSection' ||
              (!this.checkCurrentPageIsHomePage() && this.templatePage[this.currentPageIndex].components[0].sectionType === 'addNewSection'))) {
              this.templatePage[this.currentPageIndex].components.splice(this.checkCurrentPageIsHomePage() ? index : index - 1, 1, component.data);
              this.displayWebsite.remove(1)
            } else {
              // BECAUSE HEADER AND FOOTER IS PRESENT ONLY IN HOME PAGE NOT IN OTHER PAGE;
              this.templatePage[this.currentPageIndex].components.splice(this.checkCurrentPageIsHomePage() ? index : index - 1, 0, component.data);
            }

            const instance = this.websiteGenerationService.updateSection(this.displayWebsite, index, component.data, true);
            this.initiateSubscription(instance);
          })
    }

    if (this.screenWidth < 475) {
      let sectionWindow = this.templateService.openBottomSheet(V3AddSectionComponent, '', 'bottom-sheet-height');

      let index: number = response.position === 'ABOVE' ? response.index : response.index + 1;
          this.AddSkeletonSection(index, response.sectionType);

          this.templateService.getSectionForIndustry(response.componentId, this.businessDetails.subIndustryId, this.businessDetails.name, this.businessDetails.id).subscribe((component: any) => {

            let componentStyle = this.getThemeStyle();
            component.data.styles.background.color = componentStyle.bgColor;
            component.data.styles.background.accentColor = componentStyle.accentColor;

            // TO CHECK IF ADD SECTION COMPONENT IS PRESENT IN PAGE OR NOT IF YES THEN REMOVING IT
            if ((this.checkCurrentPageIsHomePage() &&
              this.templatePage[0].components[1].sectionType == 'addNewSection' ||
              (!this.checkCurrentPageIsHomePage() && this.templatePage[this.currentPageIndex].components[0].sectionType === 'addNewSection'))) {
              this.templatePage[this.currentPageIndex].components.splice(this.checkCurrentPageIsHomePage() ? index : index - 1, 1, component.data);
              this.displayWebsite.remove(1)
            } else {
              // BECAUSE HEADER AND FOOTER IS PRESENT ONLY IN HOME PAGE NOT IN OTHER PAGE;
              this.templatePage[this.currentPageIndex].components.splice(this.checkCurrentPageIsHomePage() ? index : index - 1, 0, component.data);
            }

            const instance = this.websiteGenerationService.updateSection(this.displayWebsite, index, component.data, true);
            this.initiateSubscription(instance);
          })
        }
  }

  getThemeStyle() {
    let indexFound = false;
    for (let theme of this.themes) {
      if (theme.id === this.templatePage[this.currentPageIndex].websiteThemeId) {
        indexFound = true;
        let randomIndex = Math.floor(Math.random() * (theme.componentStyles?.length ?? 1));
        let componentStyle = theme.componentStyles[randomIndex];
        return componentStyle;
      }
    }

    if (!indexFound) {
      let randomIndex = Math.floor(Math.random() * (this.themes[0].componentStyle?.length ?? 1));
      let componentStyle = this.themes[0].componentStyles[randomIndex];
      return componentStyle;
    }
  }

  AddSkeletonSection(index: number, sectionType: any) {
    let addSection = new Section();
    addSection.sectionType = 'section-loader';

    const instance = this.websiteGenerationService.addComponent(this.displayWebsite, addSection, false, index, sectionType);
    this.initiateSubscription(instance);
  }

  updateSkeletonSection(index: number, sectionType: any) {
    let addSection = new Section();
    addSection.sectionType = 'section-loader';

    const instance = this.websiteGenerationService.updateSection(this.displayWebsite, index, addSection, false, sectionType);
    this.initiateSubscription(instance);
  }

  DeleteSection() {
    console.log(this.deleteResponse);
    let index = this.deleteResponse.index;
    if (!this.checkCurrentPageIsHomePage()) {
      index--;
    }
    this.templatePage[this.currentPageIndex].components.splice(index, 1);

    if ((this.checkCurrentPageIsHomePage() && this.checkHeaderAndFooterOnlyPresent()) ||
      (!this.checkCurrentPageIsHomePage() && this.templatePage[this.currentPageIndex].components.length == 0)) {

      this.templatePage[this.currentPageIndex].components.splice(index, 0, this.createAddSection());
    }

    let sections: Section[] = []
    if (this.checkCurrentPageIsHomePage()) {
      sections = this.templatePage[0].components;
    } else {
      this.addSectionForOtherPage(sections);
    }

    const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, sections, true);
    this.initiateSubscription(instance);


    const openDelete = document.getElementById('deletepopup');
    if (!openDelete) return;
    openDelete.setAttribute("style", "display:none");
  }

  deleteResponse: any;
  deleteConfirm(response: any) {
    console.log(response)
    this.deleteResponse = response;
    const openDelete = document.getElementById('deletepopup');
    if (!openDelete) return;
    // openDelete.setAttribute("style","display:block");
    openDelete.style.display = "block";
    openDelete.style.backdropFilter = "blur(5px)";
  }

  cancelSection() {
    const openDelete = document.getElementById('deletepopup');
    if (!openDelete) return;
    openDelete.setAttribute("style", "display:none");
  }

  createAddSection() {
    let addSection = new Section();
    addSection.sectionType = 'addNewSection';
    return addSection;
  }

  addSectionForOtherPage(section: Section[]) {
    section.push(this.templatePage[0].components[0]);
    for (let sec of this.templatePage[this.currentPageIndex].components) {
      section.push(sec);
    }
    section.push(this.templatePage[0].components[this.templatePage[0].components.length - 1]);
  }

  checkCurrentPageIsHomePage() {
    return this.templatePage[this.currentPageIndex].pageName === 'Home';
  }

  checkHeaderAndFooterOnlyPresent() {
    if (this.templatePage[0].components.length == 2 && this.templatePage[0].components[0].sectionType === 'header' &&
      this.templatePage[0].components[1].sectionType === 'footer')
      return true;

    return false;
  }

  regenerateContent(response: any) {
    this.updateSkeletonSection(response.index, response.sectionType);
    let index = this.checkCurrentPageIsHomePage() ? response.index : response.index - 1;
    this.templateService.getSectionContent(response.sectionId, this.businessDetails.subIndustryId, this.businessDetails.name).subscribe((res: any) => {

      this.templatePage[this.currentPageIndex].components[index].content = res.data.data;
      this.templatePage[this.currentPageIndex].components[index].action = res.data.action;

      const instance = this.websiteGenerationService.updateSection(this.displayWebsite, response.index, this.templatePage[this.currentPageIndex].components[index], true);
      this.initiateSubscription(instance)
      this._snackBar.open(
        'Content has been updated successfully',
        'Close',
        {
          duration: 2500,
        }
      )
    }, () => {
      const instance = this.websiteGenerationService.updateSection(this.displayWebsite, response.index, this.templatePage[this.currentPageIndex].components[index], true);
      this.initiateSubscription(instance)
      this._snackBar.open(
        'No Content found',
        'Close',
        {
          duration: 2500,
        }
      )
    })
  }

  changeSectionPosition(response: any) {
    let index = this.checkCurrentPageIsHomePage() ? response.index : response.index - 1;
    let swappedIndex = response.position === 'UP' ? index - 1 : index + 1;
    let currentPage = this.templatePage[this.currentPageIndex];

    if ((this.checkCurrentPageIsHomePage() && currentPage.components[swappedIndex].sectionType === 'footer') || (swappedIndex >= currentPage.components.length)) {
      return;
    }

    [currentPage.components[index], currentPage.components[swappedIndex]] = [currentPage.components[swappedIndex], currentPage.components[index]];

    let section: Section[] = [];
    if (currentPage.pageName === 'Home') {
      section = currentPage.components;
    } else {
      this.addSectionForOtherPage(section)
    }

    const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, section, true);
    this.initiateSubscription(instance);
  }

  createDuplicateSection(response: any) {
    this.AddSkeletonSection(response.index + 1, response.data.sectionType);
    this.templateService.getSectionForIndustry(response.data.componentId, this.businessDetails.subIndustryId, this.businessDetails.name, this.businessDetails.id).subscribe((res: any) => {
      res.data.content = JSON.parse(JSON.stringify(response.data.content));
      res.data.styles = JSON.parse(JSON.stringify(response.data.styles));
      res.data.action = JSON.parse(JSON.stringify(response.data.action));
      this.templatePage[this.currentPageIndex].components.splice(response.index + 1, 0, res.data);

      const instance = this.websiteGenerationService.updateSection(this.displayWebsite, response.index + 1, res.data, true);
      this.initiateSubscription(instance);
      this._snackBar.open('Your component is duplicated', 'Close', {
        duration: 2000
      })
    })
  }

  textAlign: ALIGN[] = ['left', 'center', 'right']
  imageRatio: ImageRatio[] = []
  textPosition: TextPosition[] = [];
  footerType: FooterTypes[] = []
  corners: Corners[] = [];
  spacingType: SPACING[] = [];
  navigationStyle: HeaderButtonStyle[] = [];

  enumValue() {
    for (const value of Object.values(ImageRatio)) {
      this.imageRatio.push(value);
    }

    for (const value of Object.values(TextPosition)) {
      this.textPosition.push(value);
    }

    for (const value of Object.values(FooterTypes)) {
      this.footerType.push(value);
    }

    for (const value of Object.values(Corners)) {
      this.corners.push(value);
    }
    for (const value of Object.values(HeaderButtonStyle)) {
      this.navigationStyle.push(value);
    }
    for (const value of Object.values(SPACING)) {
      this.spacingType.push(value);
    }
  }

  regenerateComponent(response: any) {
    let index = this.checkCurrentPageIsHomePage() ? response.index : response.index - 1;
    let component = this.templatePage[this.currentPageIndex].components[index];
    if (component.sectionType === 'banner' || component.sectionType === 'text_image' || component.sectionType === 'contact us' || component.sectionType === 'location' || component.sectionType === 'chooseUs'
      || component.sectionType === 'bannerGrid' || component.sectionType === 'process' || component.sectionType === 'logoShowcase'
    ) {
      let index = component.styles.positionLayout.types.indexOf(component.styles.positionLayout.value) + 1;
      component.styles.positionLayout.value = component.styles.positionLayout.types[index % component.styles.positionLayout.types.length];
      this.eventDirective.postionLayoutChangeChecks.emit({ id: component.id, data: component.styles.positionLayout })
    }
    if (component.sectionType === 'FAQ' || component.sectionType === 'testimonial' || component.sectionType === 'text' || component.sectionType === 'headerText' || component.sectionType === 'faq'
      || component.sectionType === 'testimonialFullWidth'
    ) {
      let index = this.textAlign.indexOf(component.styles.layout.align) + 1;
      component.styles.layout.align = this.textAlign[index % this.textAlign.length];
      this.eventDirective.alignmentChangeChecks.emit({ id: component.id, data: component.styles.layout })
    }
    if (component.sectionType === 'imagegrid') {
      if (!component.styles.removeGaps || !component.styles.fullWidth) {
        component.styles.removeGaps = true;
        component.styles.fullWidth = true;

      } else {
        let index = this.imageRatio.indexOf(component.styles.image.ratio) + 1;
        component.styles.image.ratio = this.imageRatio[index % this.imageRatio.length];
        this.eventDirective.imageStyleChangeChecks.emit({ id: component.id, style: component.styles.image })
      }
    }
    if (component.sectionType === 'image') {
      let index = this.corners.indexOf(component.styles.corners) + 1;
      if (component.styles.corners === Corners.None || index <= this.corners.length - 2) {
        component.styles.corners = this.corners[index % this.corners.length];
      } else {
        component.styles.corners = this.corners[index % this.corners.length];
        component.styles.fullWidth = !component.styles.fullWidth
      }
    }
    if (component.sectionType === 'service' || component.sectionType === 'teammember') {
      let index = this.textPosition.indexOf(component.styles.textPosition) + 1;
      component.styles.textPosition = this.textPosition[index % this.textPosition.length];
    }
    if (component.sectionType === 'video') {
      component.styles.fullScreen = !component.styles.fullScreen;
      if (component.styles.layout.fit === 'content') {
        component.styles.layout.fit = 'screen'
      } else {
        component.styles.layout.fit = 'content'
      }
      this.eventDirective.spacingChangeChecks.emit({ id: component.id, data: component.styles.layout })
    }
    if (component.sectionType === 'imageCarousel') {
      const randomIdx = Math.random();
      if (randomIdx < 0.2) {
        let index = this.corners.indexOf(component.styles.corners) + 1;
        component.styles.corners = this.corners[index % this.corners.length];
      } else if (randomIdx < 0.6) {
        console.log(Object.keys(SPACING));
        const index = (Object.keys(SPACING).indexOf(component.styles.layout.spacing.bottom) + 1) % Object.keys(SPACING).length;
        component.styles.layout = { ...component.styles.layout, spacing: { ...component.styles.layout.spacing, bottom: Object.keys(SPACING)[index] } };
      } else {
        console.log(Object.keys(SPACING));
        const index = (Object.keys(SPACING).indexOf(component.styles.layout.spacing.top) + 1) % Object.keys(SPACING).length;
        component.styles.layout = { ...component.styles.layout, spacing: { ...component.styles.layout.spacing, top: Object.keys(SPACING)[index] } };
      }
    }
    if (component.sectionType === 'footer') {
      let index = this.footerType.indexOf(component.styles.footerType) + 1;
      component.styles.footerType = this.footerType[index % this.footerType.length];
    }
    else if (component.sectionType == 'header') {

      const randomIdx = Math.random();
      if (randomIdx < 0.4) {
        const index = this.navigationStyle.indexOf(component.styles.navigationStyle) + 1;
        component.styles.navigationStyle = this.navigationStyle[index % this.navigationStyle.length];
      } else if (randomIdx < 0.7) {
        const index = (Object.keys(SPACING).indexOf(component.styles.layout.spacing.bottom) + 1) % Object.keys(SPACING).length;
        component.styles.layout = { ...component.styles.layout, spacing: { ...component.styles.layout.spacing, bottom: Object.keys(SPACING)[index] } };
      } else {
        const index = (Object.keys(SPACING).indexOf(component.styles.layout.spacing.top) + 1) % Object.keys(SPACING).length;
        component.styles.layout = { ...component.styles.layout, headingSpacing: Object.keys(SPACING)[index] };
      }
    }
    else if (component.sectionType == 'pricing') {
      const isLayoutChange = Math.random() > 0.5 ? true : false;
      if (isLayoutChange) {

        const index = this.textAlign.indexOf(component.styles.layout.align) + 1;
        component.styles.layout = {
          ...component.styles.layout,
          align: this.textAlign[index % this.textAlign.length]
        };
      } else {

        const index = this.imageRatio.indexOf(component.styles.image.ratio) + 1;
        component.styles.image = {
          ...component.styles.image,
          ratio: this.imageRatio[index % this.imageRatio.length]
        }
      }
    }


  }

  createNewPage() {
    let pageData: PageData = new PageData();
    pageData.businessName = this.businessDetails.name;
    pageData.path = this.templatePage.length < 2 ? '/New_Page' : '/New_Page_' + String(this.templatePage.length - 1);
    pageData.pageName = this.templatePage.length < 2 ? 'New Page' : ('New Page ' + String(this.templatePage.length - 1));
    pageData.updatedTimeStamp = new Date()
    pageData.homePageId = this.templatePage[0].id
    pageData.id = '';

    pageData.components.push(this.createAddSection())

    this.templatePage.push(pageData);
    this.currentPageIndex = this.templatePage.length - 1;

    let header: Section = this.templatePage[0].components[0];
    let footer: Section = this.templatePage[0].components[this.templatePage[0].components.length - 1];

    let sections: Section[] = [];
    sections.push(header);
    sections.push(this.templatePage[this.currentPageIndex].components[0])
    sections.push(footer);
    const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, sections, true);
    this.initiateSubscription(instance);
  }

  changePage() {
    this.templatePage.forEach((page: PageData, index: number) => {
      if (page.id === this.currentPageId) {
        this.currentPageIndex = index;
      }
    })

    let section: Section[] = [];
    if (this.templatePage[this.currentPageIndex].pageName === 'Home') {
      section = this.templatePage[this.currentPageIndex].components;
    } else {
      this.addSectionForOtherPage(section)
    }

    let data = {
      categoryId: ''
    }
    this.getComponentData(section, this.currentPageIndex, data);

    // const instance = this.websiteGenerationService.createDynamicComponents(this.displayWebsite, section, true);
    // this.initiateSubscription(instance);
    // this.websiteLoaderfun.emit(false)
  }

  dismiss() {
    this.showEditor = false;
    this.multiTabEditor = false;
  }

  saveContactForm() {
    this.crmService.crmSaveOption.emit({ saveOption: true })
  }
  changeEditorDirection(ev) {
    if (ev === 'LEFT') {
      this.editorDirection = 'RIGHT';
    }
    else {
      this.editorDirection = "LEFT";
    }
  }

  editorTitle: string = '';
  gettingSingleBannerButton(ev: any) {
    if (ev.showSingleEditor) {
      this.singleBannerEditor = true;
      this.editorTitle = ev.data.id
      // this.multiTabEditor = true;
      let data = {
        id: this.editorTitle.toString(),
        content: { image: ev.data.image, inputText: ev.data.inputText, additionalData: ev.data.additionalData },
        styles: ev.data.styles,
        action: ev.data.action
      }
      setTimeout(() => {
        this.eventHelper.editSection.emit({ data: data });
      }, 200);
      console.log(data);
    }
  }

  addingNewBanner(ev: any) {
    console.log(ev.addItem)
    if (ev.addItem) {
      this.sectionData.content.listItem.data.push(JSON.parse(JSON.stringify(this.sectionData.content.listItem.data[0])));
      this.sectionData.content.listItem.data[this.sectionData.content.listItem.data.length - 1].inputText.forEach((text: any) => {
        text.value = ''
      });
      console.log(this.sectionData.content.listItem.data[this.sectionData.content.listItem.data.length - 1]);
      // this.sectionData.content.listItem.data[this.sectionData.content.listItem.data.length - 1].content.listItem.data[0].inputText.pop();
      // this.sectionData.content.listItem.data[this.sectionData.content.listItem.data.length - 1].content.listItem.data[0].inputText[0].value = '';
      this.sectionData.content.listItem.data[this.sectionData.content.listItem.data.length - 1].action.buttons.pop();
      this.sectionData.content.listItem.data[this.sectionData.content.listItem.data.length - 1].action.display = false;
      ev = {
        showSingleEditor: true,
        activeItem: this.sectionData.content.listItem.data.length - 1,
        data: this.sectionData.content.listItem.data[this.sectionData.content.listItem.data.length - 1]
      }
      this.gettingSingleBannerButton(ev)
    }
  }
}
