<section class="complete-section">
  <ng-container [ngSwitch]="screen">
    <ng-container *ngSwitchCase="'SELECT_FIELDS'">
      <div class="header">
        <div class="header-text">Edit Custom fields</div>
        <div class="close-direction-btns">

          <div class="close-btn" (click)="dismiss()">
            <p style="color:black;">Done</p>
          </div>
        </div>
      </div>

      <hr class="line">

      <div>
        <div>
          <div class="check-label">
            <mat-form-field class="searchField input_card " appearance="outline">
              <input placeholder="Search by field name" matInput [(ngModel)]="searchField"
                (ngModelChange)="searchListField()" />
            </mat-form-field>
            <div *ngIf="dataSource.length > 0">
              <div class="getfieldsapi" *ngFor="let element of dataSource">
                <div class="input">
                  <mat-checkbox [checked]="element.showInForm?.includes('ENQUIRY') || false"
                    (change)="updateItem($event,element)">
                    <div class="label">
                      {{element?.fieldLabel | titlecase}}
                    </div>
                  </mat-checkbox>
                </div>
                <div class="isrequired">
                  <mat-checkbox [checked]="element.required" (change)="upadteIsRequired($event,element)">
                    <div class="label"> Is Required </div>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div *ngIf="dataSource.length == 0" style="height: 75vh;"
              class="d-flex justify-content-center align-items-center">
              Sorry No Fields Found.
            </div>
          </div>




          <div class="form-check-options" *ngFor="let field of customAddedFields;let i = index">
            <div class="form-check" [ngStyle]="{'background-color' : field.required ? 'rgb(238, 234, 234)' : ''}">
              <input class="form-check-input" type="checkbox" value="" [id]="i + '_custom'" [(ngModel)]="field.status"
                [disabled]="field.required" (ngModelChange)="changeTextField(field, 'CHECK')">
              <label class="form-check-label" [for]="i + '_custom'">
                <span>{{field.label}}</span>

              </label>
              <div class="ml-auto" style="display: flex; gap: 10px">
                <mat-icon (click)="editInput(field)">edit</mat-icon>
                <mat-icon (click)="deleteInput(field)">delete_outline</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <hr class="line" />
      </div>
      <span class="addMoreField" (click)="screen = 'ADD_FIELDS'"> + Add field</span>
    </ng-container>
    <ng-container *ngSwitchCase="'ADD_FIELDS'">
      <div class="header">
        <span (click)="screen = 'SELECT_FIELDS'"><mat-icon>arrow_back</mat-icon></span>
        <div class="header-text">Add a custom field</div>
        <p class="close-btn" (click)="closeModal()"><mat-icon>close</mat-icon> </p>
      </div>

      <hr class="line">
      <div class="addFieldContainer">
        <div class="headerInfo">
          <mat-icon>info</mat-icon>
          <span>Fields added here are also added to contacts in your Enquiry</span>
        </div>
        <hr class="line">
        <p class="sub-text" style="padding-left: 0px;">Select the type of input</p>
        <div class="available-fields">
          <ng-container *ngFor="let field of availableFields">
            <div class="field-container" (click)="addNewInput(field)">
              <div class="overlay"></div>
              <div class="field-icon">
                <span *ngIf="field.customIcon">{{ field.iconTxt }}</span>
                <mat-icon *ngIf="!field.customIcon">{{ field.icon }}</mat-icon>
              </div>
              <div class="field-info">
                <span>{{ field.text | titlecase }}</span>
                <br>
                <span>{{ field.description }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'EDIT_FIELD'">
      <div class="header">
        <div *ngIf="!data.edit" class="header-text">Create the {{ getInputType === 'desc' ? 'text area' : getInputType | lowercase }} field</div>        <div *ngIf="data.edit" class="header-text">Edit the {{ getInputType | lowercase }} field</div>
        <p class="close-btn" (click)="closeModal()"><mat-icon>close</mat-icon> </p>
      </div>


      <hr class="line">
      <div class="field-edit-container">
        <hr class="line">
        <label class="fieldgroup">Field Group</label>
        <mat-form-field class="addGroupField" appearance="outline">
          <mat-select placeholder="Select Field Group" multiple [(ngModel)]="selectedFieldGroups" required>
            <mat-option *ngFor="let group of fieldGroups" [value]="group">
              {{ group.fieldGroupName }}
            </mat-option>
            <mat-option style="color: blue;" (click)="opencreategroup()">+ Create Field Group</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="mtb-15">
          <label class="fieldgroup">Field Type</label>
          <mat-form-field class="addGroupField" appearance="outline">
            <mat-select placeholder="Select Field Type" [(ngModel)]="payload.type">
              <mat-option *ngFor="let type of fieldTypeList" [value]="type.name">
                {{ type.displayText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="custom-inputs" *ngIf="selectedInputType === 'text' || selectedInputType === 'desc' ">
          <div class="body">
            <div class="label">
              <label class="input-label">Field Label</label>
              <input class="input-text" placeholder="Name" type="text" [(ngModel)]="payload.fieldLabel" required />
              <div *ngIf="!payload.fieldLabel" class="error">Field Label is required</div>
            </div>

            <div class="hint">
              <label class="input-label">Field Hint</label>
              <input class="input-text" placeholder="Enter hint for the field" type="text" [(ngModel)]="payload.hint" />
            </div>

            <div class="limits d-flex">
              <div class="limit">
                <label class="input-label">Min Limit</label>
                <input class="input-limit" type="text" [(ngModel)]="payload.minLimit" (input)="checkLimits()" />
              </div>

              <div class="limit">
                <label class="input-label">Max Limit</label>
                <input class="input-limit" type="text" [(ngModel)]="payload.maxLimit" (input)="checkLimits()" />
              </div>
            </div>

            <div *ngIf="showWarning" class="warning">
              <p style="color: red;">*Min Limit should be less than Max Limit!</p>
            </div>



            <div class="validaiton">
              <div class="v-heading">
                <label class="input-label">Validation</label>
              </div>
              <mat-form-field class="multiselect" appearance="outline">
                <mat-select class="inputStyle" placeholder="Select validation" multiple
                  [(ngModel)]="selectedValidations" (selectionChange)="updateDataFieldConstraints()">
                  <mat-option *ngFor="let validation of validationList" [value]="validation">
                    {{ validation.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </div>

        <div class="custom-inputs" *ngIf="selectedInputType === 'number'">
          <div class="body">
            <div class="label">
              <label class="input-label">Field Label</label>
              <input class="input-text" placeholder="Name" type="text" [(ngModel)]="payload.fieldLabel" required />
              <div *ngIf="!payload.fieldLabel" class="error">Field Label is required</div>
            </div>

            <div class="hint">
              <label class="input-label">Field Hint</label>
              <input class="input-text" placeholder="Enter hint for the field" type="text" [(ngModel)]="payload.hint" />
            </div>

            <div class="limits d-flex">
              <div class="limit">
                <label class="input-label">Min Limit</label>
                <input class="input-limit" type="text" [(ngModel)]="payload.minLimit" />
              </div>

              <div class="limit">
                <label class="input-label">Max Limit</label>
                <input class="input-limit" type="text" [(ngModel)]="payload.maxLimit" />
              </div>
            </div>



            <div class="validaiton">
              <div class="v-heading">
                <label class="input-label">Validation</label>
              </div>
              <mat-form-field class="multiselect" appearance="outline">
                <mat-select class="inputStyle" placeholder="Select validation" multiple
                  [(ngModel)]="selectedValidations" (selectionChange)="updateDataFieldConstraints()">
                  <mat-option *ngFor="let validation of validationList" [value]="validation">
                    {{ validation.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </div>

        <div class="custom-inputs" *ngIf="selectedInputType === 'money'">
          <div class="body">
            <div class="label">
              <label class="input-label">Field Label</label>
              <input class="input-text" placeholder="Enter amount in ₹" type="text" [(ngModel)]="payload.fieldLabel"
                required />
              <div *ngIf="!payload.fieldLabel" class="error">Field Label is required</div>
            </div>

            <div class="hint">
              <label class="input-label">Field Hint</label>
              <input class="input-text" placeholder="Enter amount" type="text" [(ngModel)]="payload.hint" />
            </div>

            <div class="limits d-flex">
              <div class="limit">
                <label class="input-label">Min Limit</label>
                <input class="input-limit" type="text" placeholder="500₹" [(ngModel)]="payload.minLimit" />
              </div>

              <div class="limit">
                <label class="input-label">Max Limit</label>
                <input class="input-limit" type="text" placeholder="5000₹" [(ngModel)]="payload.maxLimit" />
              </div>
            </div>
          </div>
        </div>

        <div class="custom-inputs" *ngIf="selectedInputType === 'date'">
          <div class="body">
            <label class="input-label">Field Label</label>
            <input class="input-text" placeholder="Name" type="text" [(ngModel)]="payload.fieldLabel" />

            <div class="hint">
              <label class="input-label">Field Hint</label>
              <input class="input-text" placeholder="Enter hint for the field" type="text" [(ngModel)]="payload.hint" />
            </div>

          </div>
        </div>

        <div class="custom-inputs" *ngIf="selectedInputType === 'checkbox'">
          <div class="body">
            <div class="label">
              <label class="input-label">Field Label</label>
              <input class="input-text" placeholder="Name" type="text" [(ngModel)]="payload.fieldLabel" required />
              <div *ngIf="!payload.fieldLabel" class="error">Field Label is required</div>
            </div>

            <div class="hint">
              <label class="input-label">Field Hint</label>
              <input class="input-text" placeholder="Enter hint for the field" type="text" [(ngModel)]="payload.hint" />
            </div>

          </div>
        </div>

        <div class="custom-inputs" *ngIf="selectedInputType === 'dropdown'">
          <label class="input-label">Dropdown Name</label>
          <input class="input-text" type="text" [(ngModel)]="payload.fieldLabel" />

          <label class="mtb-15">
            <input type="checkbox" name="" id="" [(ngModel)]="payload.multipleSelection">
            multiple Selection
          </label>

          <div class="hint">
            <label class="input-label">Field Hint</label>
            <input class="input-text" placeholder="Enter hint for the field" type="text" [(ngModel)]="payload.hint" />
          </div>

        </div>

        <div class="custom-inputs" *ngIf="selectedInputType === 'radioButton'">
          <label class="input-label">Radio Button Name</label>
          <input class="input-text" type="text" [(ngModel)]="payload.fieldLabel" />

          <div class="hint">
            <label class="input-label">Field Hint</label>
            <input class="input-text" placeholder="Enter hint for the field" type="text" [(ngModel)]="payload.hint" />
          </div>

        </div>



        <div class="custom-inputs" *ngIf="selectedInputType === 'rating'">
          <div class="body">

            <label class="input-label">Field Label</label>
            <input class="input-text" placeholder="Name" type="text" [(ngModel)]="payload.fieldLabel" />
            <p> Hint: Enter your full name</p>

            <div class="hint">
              <label class="input-label">Field Hint</label>
              <input class="input-text" placeholder="Enter hint for the field" type="text" [(ngModel)]="payload.hint" />
            </div>

            <div class="limits d-flex">
              <div class="limit">
                <label class="input-label">Min Limit</label>
                <input class="input-limit" type="text" [(ngModel)]="payload.minLimit" />
              </div>

              <div class="limit">
                <label class="input-label">Max Limit</label>
                <input class="input-limit" type="text" [(ngModel)]="payload.maxLimit" />
              </div>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="selectedInputType == 'checkbox' || selectedInputType == 'dropdown' || selectedInputType == 'radioButton'">
          <div>
            <div class="options-container">
              <span>Select Data Source</span>
            </div>
            <mat-radio-group [(ngModel)]="selectedSourceOption" (change)="onSelectionChange($event)">
              <mat-radio-button class="header-text" value="Masters">Masters</mat-radio-button>
              <mat-radio-button class="input-label" value="Custom Options">Custom Options</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="selectedSourceOption === 'Masters'">

            <mat-form-field class="addGroupField" appearance="outline">
              <mat-select placeholder="Choose Master" [(ngModel)]="selectedSourceItem" required>
                <mat-option *ngFor="let item of dataSourceTypes" [value]="item">
                  {{ item | titlecase}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container
          *ngIf="(selectedInputType === 'checkbox' || selectedInputType === 'dropdown' || selectedInputType === 'radioButton') && selectedSourceOption === 'Custom Options'">
          <div class="options-container">
            <span>Options</span>
            <span class="addMoreField-options" (click)="addMoreOption()" [ngClass]="{'disabled': !canAddMoreOption}"> +
              Add option</span>
          </div>

          <div class="optionsList" cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="custom-inputs" *ngFor="let _ of [].constructor(optionsCount); let idx = index">
              <div class="d-flex align-items-center">
                <mat-icon
                  style="font-size: 23px; color: #555555; cursor: grab; margin-right: 10px;">drag_indicator</mat-icon>
                <input class="input-text" type="text" [(ngModel)]="fieldOptions[idx].value"
                  (ngModelChange)="fieldOptions[idx].label = fieldOptions[idx].value" />
                <mat-icon [ngClass]="{'disabled': optionsCount == 1}" style="margin-left: 15px;cursor: pointer;"
                  (click)="removeOption(idx)">delete_outline</mat-icon>
              </div>
              <div class="error" *ngIf="isValueConflict(idx)">Values cannot be same</div>
            </div>
          </div>
        </ng-container>

        <label class="input-label">Error Message</label>
        <input class="input-text" type="text" [(ngModel)]="payload.errorMessage" />

        <div class="checkbox-container">
          <div class="checkbox-row">
            <ng-container *ngIf="fieldCategory==='REGISTRATION'">
              <label>
                <input type="checkbox" [(ngModel)]="payload.showInRegistration" (change)="onCheckboxChange()" />
                Show in Registration Form
              </label>
            </ng-container>
            <ng-container *ngIf="fieldCategory==='ADMISSION'">
              <label>
                <input type="checkbox" [(ngModel)]="payload.showInAdmission" (change)="onCheckboxChange()" />
                Show in Admission Form
              </label>
            </ng-container>

            <ng-container *ngIf="fieldCategory===null || fieldCategory==='ENQUIRY'">
              <label>
                <input type="checkbox" [(ngModel)]="payload.showInEnquiry" (change)="onCheckboxChange()" />
                Show in Enquiry Form
              </label>
            </ng-container>
            <label>
              <input type="checkbox" [(ngModel)]="payload.required" (change)="onCheckboxChange()" />
              Mark As Required
            </label>
          </div>

          <div class="checkbox-row">
            <label>
              <input type="checkbox" [(ngModel)]="payload.filterable" (change)="onCheckboxChange()" />
              Mark As Filterable
            </label>
            <label>
              <input type="checkbox" [(ngModel)]="payload.searchable" (change)="onCheckboxChange()" />
              Mark As Searchable
            </label>
          </div>
        </div>


        <div class="action-btn-container">
          <div *ngIf="!data.edit" class="cancel-btn" (click)="cancelEdit()">Cancel</div>
          <div *ngIf="data.edit" class="cancel-btn" (click)="cancelfromlistedit()">Cancel</div>
          <div class="save-btn" (click)="saveEdit()" [class.disabled]="!isFormValid()">Save</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>

<section class="loader_section" *ngIf="pageLoader">
  <div class="api_loader">
    <app-anime-loader></app-anime-loader>
  </div>
</section>
