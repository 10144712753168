import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/services/template.service';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { GenerateWebsitePopupComponent } from '../generate-website-popup/generate-website-popup.component';
import { map, Observable, of, startWith } from 'rxjs';

@Component({
  selector: 'app-business-info-form',
  templateUrl: './new-w-type.component.html',
  styleUrls: ['./new-w-type.component.scss']
})
export class NewWTypeComponent implements OnInit {
  @Output() next = new EventEmitter<void>();


  background: string = "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/500415c1739434186310image%2016.png"


  businessForm = new FormControl(''); 
  websiteType: 'unknown' | 'E_COMMERCE' | 'STATIC' = 'unknown';
  steps = ['Business Info', 'Location', 'Business Name'];
  currentStep = 1;
  showOptions = false;

  private readonly ECOMMERCE_KEYWORDS = [
    'shop', 'store', 'retail', 'commerce', 'ecommerce', 'e-commerce',
    'sell', 'sales', 'product', 'marketplace', 'merchant', 'clothing',
    'fashion', 'jewelry', 'shoes', 'electronics', 'toys', 'gifts',
    'furniture', 'bookstore', 'grocery', 'bakery', 'food delivery',
    'subscription', 'dropshipping'
  ];

  private readonly INFORMATIVE_KEYWORDS = [
    'blog', 'news', 'magazine', 'information', 'media', 'agency',
    'service', 'law', 'legal', 'consulting', 'advisor', 'coach',
    'education', 'school', 'university', 'non-profit', 'charity',
    'foundation', 'church', 'temple', 'mosque', 'religious',
    'healthcare', 'clinic', 'doctor', 'dentist', 'therapist',
    'counselor', 'consultant', 'portfolio', 'restaurant'
  ];

  constructor(private fb: FormBuilder, private router: Router,
    private webService: TemplateService,
  ) { }

  ngOnInit() {
    this.getAllIndustries()
    this.getScreenSize()
  }

  determineWebsiteType(input: string |  null) {
    if (!input) {
      this.websiteType = 'unknown';
      this.showOptions = false;
      return;
    }

    const lowerInput = input.toLowerCase();

    const isEcommerce = this.ECOMMERCE_KEYWORDS.some(keyword =>
      lowerInput.includes(keyword)
    );

    const isInformative = this.INFORMATIVE_KEYWORDS.some(keyword =>
      lowerInput.includes(keyword)
    );

    if (isEcommerce && !isInformative) {
      this.websiteType = 'E_COMMERCE';
      this.showOptions = true;
    } else if (isInformative && !isEcommerce) {
      this.websiteType = 'STATIC';
      this.showOptions = true;
    } else if (isEcommerce && isInformative) {
      this.websiteType = 'STATIC';
      this.showOptions = true;
    } else {
      this.websiteType = 'unknown';
      this.showOptions = true;
    }
    this.selectedIndustry = {
      name: '',
      industryId: '',
      subIndustryId: '',
      industryName: '',
      subIndustryName: '',
      subIndustryType : ''
    }
  }

  handleWebsiteTypeClick(type: 'E_COMMERCE' | 'STATIC') {
    this.websiteType = type;
  }
  industries: Industry[] = [];
  filteredIndustries: Observable<Industry[]> | any = of([]);
  selectedIndustry: Industry = {
    name: '',
    industryId: '',
    subIndustryId: '',
    industryName: '',
    subIndustryName: '',
    subIndustryType : ''
  }

  private _filterIndustries(value: any) {
    const filterValue = value.toLowerCase();

    return this.industries.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  getAllIndustries() {
    this.webService.getAllSubIndustries().subscribe({
      next: (res: any) => {
        this.industries = res.data
        this.filteredIndustries = this.businessForm.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterIndustries(value))
        );
      }
    }
    )
  }
  selectBusinessType(ev: any) {
    if(ev.subIndustryType == 'STATIC')
      this.websiteType = 'STATIC'
    if(ev.subIndustryType == 'E_COMMERCE')
      this.websiteType = 'E_COMMERCE'
    console.log(ev)
    this.selectedIndustry.name = ev.name;
    this.selectedIndustry.industryId = ev.parent.id;
    this.selectedIndustry.industryName = ev.parent.name;
    this.selectedIndustry.subIndustryId = ev.id;
    this.selectedIndustry.subIndustryName = ev.name;
  }
  leadId: any;
  storeBDetails() {
    localStorage.setItem(StorageKeys.SUBINDUSTRYID, this.selectedIndustry.subIndustryId)
    localStorage.setItem(StorageKeys.INDUSTRY_DETAILS, JSON.stringify(this.selectedIndustry));
    localStorage.setItem(StorageKeys.WEBSITE_TYPE, this.websiteType);
    console.log(this.selectedIndustry,this.websiteType)
    this.router.navigate(['/select-location']);
  }

  screenWidth : any
  getScreenSize(){
    this.screenWidth = window.innerWidth
  }
}