<section class="media-selectors">
  <div class="header__media-selector">
    <h1 class="heading__media-selector">Media Selector</h1>

    <div *ngIf="simpoImageList.length > 0" class="libraries__media-selector">
      <ng-container *ngFor="let library of libraries">
        <span class="library-title" [class.active]="library.value === selectedLibrary"
          (click)="library.value === selectedLibrary ? null : changeLibrary(library)">{{ library.text }}
        </span>
      </ng-container>
    </div>
    <div *ngIf="simpoImageList.length === 0" class="libraries__media-selector">
      <ng-container *ngFor="let library of libraries">
        <span *ngIf="library.value !== LIBRARY.SIMPO_LIBRARY" class="library-title"
          [class.active]="library.value === selectedLibrary"
          (click)="library.value === selectedLibrary ? null : changeLibrary(library)">
          {{ library.text }}
        </span>
      </ng-container>
    </div>
    <div class="l-10" *ngIf="selectedLibrary === 'CUSTOM_LIBRARY'">
      <p>The latest 10 images will be displayed</p>
    </div>
    <hr class="horizontal-line" />

    <div class="search-bar__media-selector" *ngIf="
        selectedLibrary === 'UNSPLASH'
      ">
      <div class="input-bar__search">
        <mat-form-field class="media-selector-form-field" appearance="outline">
          <span matPrefix><img src="../../../../../../../../assets/images/common/search_icon.svg" alt="Search" />
            &nbsp;</span>
          <input type="text" matInput [placeholder]="'Search ' + selectedLibrary.toLowerCase()" [(ngModel)]="searchText"
            (ngModelChange)="pageNo = 1; handleSearch()" />
        </mat-form-field>
      </div>
      <div class="icon__search" (click)="searchBasedOnLibrary()">
        <mat-icon>search</mat-icon>
      </div>
    </div>
  </div>

  <div class="upload__media-selection" *ngIf="selectedLibrary === 'UPLOAD'"
    [ngStyle]="{ padding: !imagesList.length ? '' : '0px' }">
    <div class="upload-box" (click)="fileInput.click()">
      <span class="icon__upload-box"><img src="../../../../../../../../assets/images/editor/uploadIcons.svg"
          alt="Upload" /></span>
      <span class="text__upload-box"><a>Click to upload</a> or drag and drop
      </span>
      <span class="conditions__upload-box">PNG or JPG (upto 4mb)</span>
    </div>

    <!-- <div
      class="upload-box__add-more"
      (click)="fileInput.click()"
      *ngIf="imagesList.length > 0"
    >
      <span class="icon__upload-box"
        ><img
          src="../../../../../../../../assets/images/editor/uploadIcons.svg"
          alt="Upload"
      /></span>
      <span class="text__upload-box"><a>+Add More</a> </span>
    </div> -->
  </div>

  <div class="container__media-selector" *ngIf="selectedLibrary === 'UPLOAD'" [ngStyle]="{
      'flex-grow': !imagesList.length ? '0' : '1',
    }" style="width: 100%; flex-wrap: nowrap; overflow-x: scroll">
    <ng-container *ngFor="let image of imagesList">
      <div class="media__container" [class.selected]="!image.selected" style="min-width: 200px; min-height: 150px"
        (click)="selectImage(image)">
        <img [src]="image.assets[0].url" [alt]="image.altDescription" />
        <span class="delete__media"
          (click)="$event.stopPropagation(); removeUploadedPhoto(image)"><mat-icon>delete</mat-icon> Remove</span>
      </div>
    </ng-container>
  </div>

  <div class="container__media-selector" *ngIf="
      selectedLibrary === 'UNSPLASH' ||
      selectedLibrary === 'CUSTOM_LIBRARY'
    " [ngStyle]="{
      padding: !imagesList.length ? '0px' : '',
    }">
    <ng-container *ngIf="!showScreenLoader; else LoadingScreen">
      <ng-container *ngIf="
          selectedLibrary === 'UNSPLASH'
        ">
        <ng-container *ngIf="imagesList.length > 0; else EmptyScreen">
          <ng-container *ngFor="let image of imagesList">
            <div class="media__container" [class.selected]="image.selected" (click)="selectImage(image)">
              <img [src]="image.assets[0].url" [alt]="image.altDescription" #imageEle />
              <image-loading [hash]="image.blurHash" [image]="imageEle" style="height: 100%; width: 100%;"
                *ngIf="screenWidth > 475"></image-loading>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedLibrary === 'CUSTOM_LIBRARY'">
        <ng-container *ngIf="(libraryImagesList | json) != '{}'; else EmptyScreen">
          <ng-container *ngFor="let image of libraryImagesList | keyvalue">
            <div class="media__container" [class.selected]="image.value.selected" (click)="selectImage(image.value)">
              <img [src]="image.value.assets[0].url" [alt]="image.value.altDescription" />
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #LoadingScreen>
      <div class="loading_screen">Loading...</div>
    </ng-template>

    <!-- Used for intersection observer to load when this element is visible -->
    <div class="loading" #MoreLoader>
      <span></span>
    </div>
  </div>

  <div class="container__media-selector" *ngIf="selectedLibrary === 'SIMPO_LIBRARY'">
    <ng-container *ngFor="let image of simpoImageList">
      <div class="media__container" [ngClass]="{'selected-image' : image.selected}"
        (click)="selectFromSimpoLibrary(image)">
        <img [src]="image.url" [alt]="image.altText" />
      </div>
    </ng-container>
  </div>

  <input type="file" style="display: none" (change)="uploadImages($event)" multiple
    accept="image/png, image/jpeg, image/jpg, image/gif" #fileInput />

  <div class="footer__media-selector" *ngIf="screenWidth > 475">
    <div class="credits__footer" *ngIf="
        selectedLibrary === 'UNSPLASH'
      ">
      Powered By <a>{{ selectedLibrary | titlecase }}</a>
    </div>
    <div class="buttons__footer">
      <span style="font-size: 14px; color: tomato">Large Image can take upto 2-3 mins to upload</span>
      <button class="outlined-button" [disabled]="showButtonLoader" (click)="close()">
        Cancel
      </button>

      <button class="flat-button" (click)="insertImages()" *ngIf="!showButtonLoader; else BtnLoader">
        Save & Insert
      </button>
    </div>
  </div>

  <div class="footer__media-selector" *ngIf="screenWidth <= 475">
    <div class="credits__footer" *ngIf="
        selectedLibrary === 'UNSPLASH'
      ">
      Powered By <a>{{ selectedLibrary | titlecase }}</a>
    </div>
    <div class="buttons__footer"  style="flex-direction: column;">
      <div>
      <span style="font-size: 14px; color: tomato">Large Image can take upto 2-3 mins to upload</span>
      </div>
      <div class="media_button_section">
      <button class="outlined-button" [disabled]="showButtonLoader" (click)="close()">
        Cancel
      </button>
      <button class="flat-button" (click)="insertImages()" *ngIf="!showButtonLoader; else BtnLoader">
        Save & Insert
      </button>
      </div>
    </div>
  </div>

  
</section>

<ng-template #BtnLoader>
  <button class="flat-button"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</button>
</ng-template>

<ng-template #EmptyScreen>
  <div class="empty__media-selector">
    <h2>No Media Found</h2>
    <p>Looks like you have not added any media</p>
  </div>
</ng-template>