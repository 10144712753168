<div class="container">
    <div class="card">
        <div class="card-content">
            <div class="logo-container">
                <div class="logo-circle" [style.background]="getMainBg()">
                    <div class="logo-icon">{{ getMainIcon() }}</div>
                </div>
                <div class="decorative-circle circle" [style.background]="getCornerBg(0)">
                    <div class="decorative-icon">{{ getCornerIcon(0) }}</div>
                </div>
                <div class="decorative-circle circle-green" [style.background]="getCornerBg(1)">
                    <div class="decorative-icon"> {{ getCornerIcon(1) }}
                    </div>
                </div>
                <div class="decorative-circle circle-yellow" [style.background]="getCornerBg(2)">
                    <div class="decorative-icon">{{ getCornerIcon(2) }}</div>
                </div>
            </div>

            <h1 class="title">Building Your Website</h1>
            <p class="subtitle"> {{ loadingMessages[messageIndex] }}</p>

            <div class="progress-container">
                <div class="progress-bar">
                    <div class="progress-fill" [style.width.%]="progress"></div>
                </div>
                <p class="progress-text"> {{ progress }}% Complete
                </p>
            </div>

            <div class="loading-container">
                <div class="loading-dots">
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                </div>
                <p class="loading-text">Please wait while we work our magic</p>
            </div>
        </div>
    </div>
</div>