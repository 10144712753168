import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrmServiceService } from '../../../../service/crm-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db-service/db.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgFor, AsyncPipe, DatePipe } from '@angular/common';
import moment from 'moment';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { PricingDetailsComponent } from 'src/app/shared-module-files/components/pricing-details/pricing-details.component';

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
  }
};
@Component({
  selector: 'app-create-call',
  templateUrl: './create-call.component.html',
  styleUrls: ['./create-call.component.scss', './mq-create-call.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ]
})
export class CreateCallComponent {

  constructor(
    public dialogRef: MatDialogRef<CreateCallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crmService: CrmServiceService,
    private snackbar: MatSnackBar,
    private dbService: DbService,
  ) {

  }

  ngOnInit() {
    this.getStudentList();
    if((this.data?.data?.leadName?.length || 0) > 0)
      this.selectedContact = this.data.data.leadName
    if (this.data?.data?.edit && this.data?.data?.type !== 'ACTIVITY') {
      this.callDetails = JSON.parse(JSON.stringify(this.data.data.data));
      console.log(this.callDetails)
      this.callDetails.startDateTime = new Date(this.callDetails.startDateTime);
      this.getStartTime(this.callDetails.startDateTime);
    }
    if (this.data.data.type == 'ACTIVITY' && this.data.data.edit) {
      this.getCallsById()
    }

    if ((this.data.data.type == 'CALLS' || this.data.data.type == 'ACTIVITY' || this.data.data.type == 'ALL_CALLS') && !this.data.data.edit) {
      this.callDetails.leadName = this.dbService.getLeadDetails().personalInfo.name;
      this.callDetails.leadId = this.dbService.getLeadDetails().id;
      this.selectedContact = this.dbService.getLeadDetails().personalInfo?.name;
    }

    if (this.data.data.edit && this.data.data.type === 'ALL_CALLS') {
      this.callDetails = JSON.parse(JSON.stringify(this.data.data.data));
      this.selectedContact = this.callDetails.leadName;
    }


    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }
  minDate: Date = new Date();
  selectedOption: string = "INDIVIDUAL";
  selectedContact = ""
  callTypes = [
    { value: "OUT_BOUND", viewValue: "OutBound" },
    { value: "IN_BOUND", viewValue: "InBound" },
  ]

  myControl = new FormControl('');
  options: any[] = []
  filteredOptions: Observable<any> | undefined;


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(this.options)
    return this.options.filter(option => option.personalInfo.name.toLowerCase().includes(filterValue));
  }

  callDetails: any = {
    // callId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    businessId: this.dbService.getBusinessId(),
    title: "",
    startDateTime: "",
    callStartTime: "",
    call_variant: "SCHEDULE",
    callStatus: "SCHEDULED",
    callType: "OUT_BOUND",
    remainder: 0,
    callDuration: "",
    callPurpose: "DEMO",
    otherReason: "",
    description: "",
    leadId: "",
    leadName: "",
    createdById: localStorage.getItem(StorageKeys.STAFF_ID),
    createdByName: localStorage.getItem(StorageKeys.STAFF_NAME),
    createdTimeStamp: new Date(),
    updatedTimeStamp: new Date()
  }

  hourReminder = [
    { value: 0, viewValue: 'No Reminder' },
    { value: 10, viewValue: '10 minutes Before' },
    { value: 20, viewValue: '20 minutes Before' },
    { value: 30, viewValue: '30 minutes Before' },
    { value: 40, viewValue: '40 minutes Before' },
    { value: 50, viewValue: '50 minutes Before' },
    { value: 60, viewValue: '1 hour Before' },
    { value: 120, viewValue: '2 hours Before' },
    { value: 180, viewValue: '3 hours Before' },
    { value: 240, viewValue: '4 hours Before' },
    { value: 1440, viewValue: '1 day Before' },
    { value: 2880, viewValue: '2 days Before' },
  ]
  status = [
    { value: "NEW", viewValue: "New" },
    { value: "ANSWERED", viewValue: "Answered" },
    { value: "NOT_ANSWERED", viewValue: "Not Answered" },
    { value: "SCHEDULED", viewValue: 'Scheduled' },
    { value: "NO_RESPONSE", viewValue: "Noresponse/Busy" },
    { value: "DO_NOT_CALL", viewValue: "Do not Call" },
    { value: "FAILED", viewValue: "Failed" },
    { value: "MISSED_CALL", viewValue: "Missed Call" },
    { value: "COMPLETED", viewValue: "Completed" },
  ]


  callPurpose = [
    { value: "DEMO", viewValue: "Demo" },
    { value: "PROJECT", viewValue: "Project" },
    { value: "DESK", viewValue: "Desk" },
    { value: "NEGOTIATION", viewValue: 'Negotiation' },
    { value: "ADMINISTRATIVE", viewValue: "Administrative" },
    { value: "PROSPECTING", viewValue: "Prospecting" },
    { value: "OTHERS", viewValue: "Others" },
  ]

  timeArray = [
    { value: "12", viewValue: "12:00 AM" },
    { value: "01", viewValue: "1:00 AM" },
    { value: "02", viewValue: "2:00 AM" },
    { value: "03", viewValue: "3:00 AM" },
    { value: "04", viewValue: "4:00 AM" },
    { value: "05", viewValue: "5:00 AM" },
    { value: "06", viewValue: "6:00 AM" },
    { value: "07", viewValue: "7:00 AM" },
    { value: "08", viewValue: "8:00 AM" },
    { value: "09", viewValue: "9:00 AM" },
    { value: "10", viewValue: "10:00 AM" },
    { value: "11", viewValue: "11:00 AM" },
    { value: "24", viewValue: "12:00 PM" },
    { value: "13", viewValue: "1:00 PM" },
    { value: "14", viewValue: "2:00 PM" },
    { value: "15", viewValue: "3:00 PM" },
    { value: "16", viewValue: "4:00 PM" },
    { value: "17", viewValue: "5:00 PM" },
    { value: "18", viewValue: "6:00 PM" },
    { value: "19", viewValue: "7:00 PM" },
    { value: "20", viewValue: "8:00 PM" },
    { value: "21", viewValue: "9:00 PM" },
    { value: "22", viewValue: "10:00 PM" },
    { value: "23", viewValue: "11:00 PM" },
  ];

  callStartTime: string = '';
  dateTimeFormat: string = 'YYYY-MM-DDTHH:mm';

  setScheduleDateTime() {
    this.startDate = new Date(this.startDate as Date);
    if (this.startDate) {
      if (this.callStartTime) {
        const timings = this.callStartTime.split(':');

        this.callDetails.startDate = new Date(new Date(new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()).setHours(Number(timings[0]))));
        // this.callDetails.startDateTime = moment(new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), Number(timings[0].trim()), Number(timings[1].trim()))).format(this.dateTimeFormat);
      } else
      {
        this.callDetails.startDateTime = moment(new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate())).format(this.dateTimeFormat);
      }
    } else if (this.callStartTime) {
      const timings = this.callStartTime.split(':');

      this.callDetails.startDateTime = moment(this.callDetails.startDateTime ? this.callDetails.startDateTime : new Date()).set({ 'hour': Number(timings[0].trim()),  }).format(this.dateTimeFormat);
    }
  }

  getStartTime(date: any) {
    this.startDate = new Date(date);

    this.callStartTime = `${this.startDate.getHours().toString().padStart(2, '0')} : ${this.startDate.getMinutes().toString().padStart(2, '0')}`
  }

  transformedOptions: { id: string; fieldValue: string }[] = []; //
  getStudentList() {
    this.crmService.searchStudentList(this.dbService.getBusinessId(), this.selectedContact).subscribe(
      (res: any) => {
        this.options = res.data;

        // Transform and filter options
        this.transformedOptions = this.options
          .map(staff => {
            // Check if fieldData exists and has the required data
            const field = staff.fieldData.find(f => f.type === 'STUDENT_NAME');
            return field ? { id: staff.id, fieldValue: field.fieldValue } : null;
          })
          .filter((option): option is { id: string; fieldValue: string } => option !== null); // Type guard
      },
      (err) => {
        this.snackbar.open(`${err.error.message}`, 'Close', { duration: 1500 });
      }
    );
  }
  cancel() {
    this.dialogRef.close()
  }
  addLoader: boolean = false;
  startDate: Date | null = null;
  minHours: number = new Date().getHours();
  minMin: number = new Date().getMinutes();
  createLoader: boolean = false;
  createCall() {
    this.createLoader = true;
    this.crmService.createCall(this.callDetails).subscribe(
      (res: any) => {
        this.dialogRef.close("success");
        this.addLoader = false;
      },
      (err) => {
        if(err.error.errorCode === 2000) {
          this.crmService.openSocialBrand(PricingDetailsComponent, '100vh', '100%', {})
        }
        this.createLoader = false
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }
  updateLoader: boolean = false;
  editCall() {
    this.updateLoader = true;
    this.crmService.editCall(this.callDetails).subscribe(
      (res: any) => {
        this.dialogRef.close()
        this.updateLoader = false;
      },
      (err) => {
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    )
  }

  participantType: any = "LEAD"
  type = [
    { value: "LEAD", viewValue: "Leads" },
    { value: "CUSTOMER", viewValue: "Customers" }
  ]
  pageNo = 0;
  size = 100;
  getContacts() {
    this.crmService.searchLeadsCustomers(this.dbService.getBusinessId(), 0, 10, this.participantType, this.selectedContact).subscribe(
      (res: any) => {
        this.options = [];
        this.options = res.data.data;
        console.log(this.options)
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
        console.log(this.filteredOptions)
      },
      (err) => {
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          { duration: 1500 }
        )
      }
    )
  }
  contactSelected(contactData: any) {
    this.callDetails.leadName = contactData.personalInfo.name;
    this.callDetails.leadId = contactData.id;
  }
  getCallsById() {
    this.crmService.getCallDetails(this.data.data.callId).subscribe(
      (res: any) => {
        this.callDetails = res.data;
        this.getStartTime(this.callDetails.startDateTime);
      }
    )
  }
  changingStatus() {
    if (this.callDetails.call_variant === 'SCHEDULE' && this.startDate) {
      this.startDate = new Date();
    }

    this.callDetails.callDuration = null;
    this.callDetails.callType = "OUT_BOUND";
  }

  get isTodayDate(): boolean {
    if (this.callDetails.call_variant === 'SCHEDULE' && this.startDate) {
      this.minHours = new Date().getHours();
      this.minMin = new Date().getMinutes();

      return moment(this.startDate).isSame(Date.now(), 'day');
    }

    return false;
  }

  selectTime(item: any) {
    this.callDetails.reminderDate = moment(this.callDetails.reminderDate).set({ hour: item.value, minute: 0 }).format();
  }

  isTimeDisabled(item: any): boolean {
    const currentTime = new Date();
    const isToday = this.startDate ? moment(this.startDate).isSame(currentTime, 'day') : false;
    if (this.callDetails.call_variant === 'SCHEDULE' && isToday) {
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();

      const [hour, period] = item.viewValue.split(':');
      const hourIn24 = this.convertTo24HourFormat(hour.trim(), period.trim());
      const itemMinutes = parseInt(item.viewValue.split(':')[1].trim());

      if (parseInt(hourIn24) < currentHour || (parseInt(hourIn24) === currentHour && itemMinutes < currentMinute)) {
        return true;
      }
    }

    return false;
  }


  convertTo24HourFormat(hour: string, period: string): string {
    if (period.includes('PM') && parseInt(hour) !== 12) {
      return (parseInt(hour) + 12).toString();
    }
    if (period.includes('AM') && parseInt(hour) === 12) {
      return '00';
    }
    return hour;
  }

}
