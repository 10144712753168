import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageUploadService } from 'src/app/services/ImageUploadService/image-upload.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Inject,
  HostListener,
} from '@angular/core';
import { Media, MediaSelectorDialogDataType, MediaSource } from 'src/app/services/template-constants';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { encode } from 'blurhash';
import { MatSnackBar } from '@angular/material/snack-bar';

interface Library {
  text: string;
  value: LIBRARY;
}

enum LIBRARY {
  'UNSPLASH' = 'UNSPLASH',
  //'SHUTTERSTOCK' = 'SHUTTERSTOCK',
  'UPLOAD' = 'UPLOAD',
  'CUSTOM_LIBRARY' = 'CUSTOM_LIBRARY',
  'SIMPO_LIBRARY' = 'SIMPO_LIBRARY'
}
@Component({
  selector: 'app-media-selector',
  templateUrl: './media-selector.component.html',
  styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent implements OnInit, AfterViewInit {
  public LIBRARY = LIBRARY
  postType: any;

  constructor(
    private _dialogRef: MatDialogRef<MediaSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: MediaSelectorDialogDataType,
    // private imageUploadService:ComponentImageUploadService,
    private templateService: TemplateService,
    private imageUploadService: ImageUploadService,
    private dbService: DbService,
    private _snackBar: MatSnackBar
  ) {
    this.getScreenSize();
  }
  ngOnInit(): void {
    if (this.dialogData['type']) {
      this.postType = this.dialogData['type']
    }
    console.log(this.postType)
    this.businessId = this.dbService.getBusinessId() as string;
    let bdetails = JSON.parse(String(localStorage.getItem('bDetails')))
    this.searchText = bdetails.subIndustryName;
    this.getAllSimpoLibraryImage()
    this.searchBasedOnLibrary();
    this.getPhotosFromLibrary();
  }

  screenWidth: any;
  @HostListener('window: resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit(): void {
    this.createIntersectionObserver();
  }
  @ViewChild('MoreLoader') loaderElement: ElementRef | undefined;
  libraries: Library[] = [
    { text: 'Unsplash', value: LIBRARY.UNSPLASH },
    // { text: 'Shutterstock', value: LIBRARY.SHUTTERSTOCK },
    { text: 'Upload', value: LIBRARY.UPLOAD },
    { text: 'Library', value: LIBRARY.CUSTOM_LIBRARY },
    { text: 'Simpo Library', value: LIBRARY.SIMPO_LIBRARY }
  ];

  selectedLibrary: LIBRARY = LIBRARY.UNSPLASH;

  searchText: string = '';
  pageNo: number = 1;
  pageSize: number = 10;

  selectedImages: { [key: string]: Media } = {};

  uploadedImagesList: Media[] = [];
  imagesList: Media[] = [];

  libraryImagesList: { [key: string]: Media } = {};

  totalPages: number = 0;

  showScreenLoader: boolean = false;
  showButtonLoader: boolean = false;

  businessId: string = '';

  close() {
    this._dialogRef.close();
  }
  createIntersectionObserver() {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) return;
      // load more content;
      if (this.pageNo === this.totalPages) return;

      this.pageNo++;
      this.searchBasedOnLibrary();
    });
    // start observing
    intersectionObserver.observe(this.loaderElement?.nativeElement);
  }

  changeLibrary(library: Library) {
    this.selectedLibrary = library.value;
    this.pageNo = 1;
    this.searchBasedOnLibrary();
  }

  handleSearch = this.templateService.debounce(() =>
    this.searchBasedOnLibrary()
  );

  searchBasedOnLibrary() {
    if (this.selectedLibrary === LIBRARY.UNSPLASH) {
      this.searchUnsplashPhotos();
      // } else if (this.selectedLibrary === LIBRARY.SHUTTERSTOCK) {
      //   this.searchPhotosInShutterstock();
      // }
    } else if (this.selectedLibrary === LIBRARY.UPLOAD) {
      this.imagesList = this.uploadedImagesList;
    } else {
      for (let uniqueId in this.libraryImagesList) {
        if (this.selectedImages.hasOwnProperty(uniqueId)) {
          this.libraryImagesList[uniqueId].selected = true;
        } else {
          this.libraryImagesList[uniqueId].selected = false;
        }
      }
    }
  }

  simpoImageList: any[] = [];
  getAllSimpoLibraryImage() {
    let subId = JSON.parse(String(localStorage.getItem('bDetails'))).subIndustryId;
    let payload = {
      subId: subId
    }
    this.templateService.getImageData(payload).subscribe((res: any) => {
      this.simpoImageList = res.data.data;
      this.simpoImageList.forEach(image => {
        image['selected'] = false;
      })
    })
  }

  searchUnsplashPhotos() {
    if (!this.searchText)
      return;
    if (this.pageNo === 1) {
      this.showScreenLoader = true;
      this.imagesList = [];
    }
    this.templateService
      .searchPhotosInUnsplash(
        this.searchText || 'technology',
        this.pageNo,
        this.pageSize
      )
      .subscribe({
        next: (response: any) => {
          response.results.forEach((image: any) => {
            this.imagesList.push({
              businessId: this.businessId,
              uniqueId: image.id,
              blurHash: image.blur_hash,
              assets: [
                {
                  width: image.width,
                  height: image.height,
                  url: image.urls.raw,
                  blurhash: image.blur_hash
                },
              ],
              author: {
                id: image.user.id,
                userName: image.user.username,
                name: image.user.name,
                twitterUserName: image.user.twitter_username,
                portFolioUrl: image.user.portfolio_url,
                instagramUserName: image.user.instagram_username,
              },
              media: MediaSource.UNSPLASH,
              description: image.description,
              altDescription: image.alt_description,
              selected: this.selectedImages.hasOwnProperty(image.id),
            });
          });

          this.totalPages = response.total_pages;
        },
        complete: () => {
          this.showScreenLoader = false;
        },
      });
  }

  searchPhotosInShutterstock() {
    if (this.pageNo === 1) {
      this.showScreenLoader = true;
      this.imagesList = [];
    }

    this.templateService
      .searchPhotosInShutterstock(
        this.searchText || 'technology',
        this.pageNo,
        this.pageSize
      )
      .subscribe({
        next: (response: any) => {
          response.data.forEach(async (image: any) => {
            const imageUrlSplit = image.assets.preview.url.split('/');
            // const blurhash = (await this.generateBlurhash(image.assets.preview_1000.url)).hash;
            this.imagesList.push({
              businessId: this.businessId,
              uniqueId: image.id,
              blurHash: null,
              assets: [
                {
                  width: image.assets.preview_1000.width,
                  height: image.assets.preview_1000.height,
                  url: image.assets.preview_1000.url,
                  blurhash: ''
                },
              ],
              author: {
                id: image.contributor.id,
                userName: null,
                name: null,
                twitterUserName: null,
                portFolioUrl: null,
                instagramUserName: null,
              },
              media: MediaSource.SHUTTER_STOCK,
              description: image.description,
              altDescription: imageUrlSplit[imageUrlSplit.length - 1],
              selected: this.selectedImages.hasOwnProperty(image.id),
            });
          });

          this.totalPages = Math.ceil(response.total_count / this.pageSize);
        },
        complete: () => {
          this.showScreenLoader = false;
        },
      });
  }

  getPhotosFromLibrary() {
    if (this.pageNo === 1) {
      this.showScreenLoader = true;
      this.imagesList = [];
    }

    this.templateService
      .getSavedImagesInLibrary(
        this.businessId,
        '',
        this.pageNo - 1,
        this.pageSize
      )
      .subscribe({
        next: (response: any) => {
          response.data.content.forEach((image: Media) => {
            this.libraryImagesList[image.uniqueId] = {
              ...image,
              description: image.description,
              altDescription: image.altDescription,
              selected: false,
            };
          });

          this.totalPages = Math.ceil(
            response.data.totalElements / this.pageSize
          );
        },
      });
  }
  // selectImage(selectedImage: Media) {
  //   if (
  //     this.dialogData.hasOwnProperty('multiple') &&
  //     !this.dialogData['multiple']
  //   ) {
  //     const previousSelectedImageIndex = this.imagesList.findIndex(
  //       (img: any) => img.selected
  //     );

  //     if (previousSelectedImageIndex > -1) {
  //       this.imagesList[previousSelectedImageIndex].selected = false;
  //     }
  //   }

  //   selectedImage.selected = !selectedImage.selected;

  //   if (selectedImage.selected) {
  //     this.selectedImages[selectedImage.uniqueId] = selectedImage;
  //   } else {
  //     delete this.selectedImages[selectedImage.uniqueId];
  //   }
  // }

  selectImage(selectedImage: Media) {
    if (
      this.dialogData.hasOwnProperty('multiple') &&
      !this.dialogData['multiple']
    ) {
      this.selectedImages = {};
      this.imagesList.forEach((img: any) => img.selected = false);
    }

    selectedImage.selected = !selectedImage.selected;

    console.log('latest selected image', selectedImage);

    if (selectedImage.selected) {
      this.selectedImages[selectedImage.uniqueId] = selectedImage;
    } else {
      delete this.selectedImages[selectedImage.uniqueId];
    }
  }

  uploadImages(event: any) {
    let files = event.target.files;

    const maximumFileSize = 4 * 1024 * 1024; // Max is 4mb

    // if upload is happening from smm side, then restricting video/pdf upload specifically
    if (this.postType && this.postType == 'smm-post-image') {
      for (let file of files) {
        if (file.type == "video/mp4" || file.type == "application/pdf") {
          console.log("is video or pdf")
          this._snackBar.open('Video/MP4 or PDF uploading is not allowed!', 'Close', {
            duration: 2000,
          });
          return;
        }
      }
    }

    for (let file of files) {
      // File size is in bytes
      if (maximumFileSize < file.size) {
        this.templateService.openSnackbar('Maximum file size limit is 4MB');
        continue;
      }

      const reader = new FileReader();

      const selectedImage = new Image();
      const image: Media = {
        businessId: this.businessId,
        uniqueId: file.name + '_' + file.size,
        blurHash: null,
        assets: [{
          width: 0,
          height: 0,
          blurhash: '',
          url: '',
        }],
        author: null,
        media: MediaSource.DEVICE,
        description: file.name,
        altDescription: file.name,
        selected: this.selectedImages.hasOwnProperty(file.name),
        file: file,
      };

      reader.onload = (response: any) => {
        image.assets[0].url = response.target.result;
        selectedImage.src = image.assets[0].url;

        this.uploadedImagesList = [image, ...this.uploadedImagesList];
        this.imagesList = [...this.uploadedImagesList];

        // Automatically select the uploaded image
        this.selectImage(image);
      };

      // Update the File Height and Width.
      selectedImage.onload = function () {
        image.assets[0].height = selectedImage.height;
        image.assets[0].width = selectedImage.width;
      };

      if (this.imagesList.find((img: Media) => img.description === file.name))
        continue;

      reader.readAsDataURL(file);
    }

  }

  async selectFromSimpoLibrary(simpoImage: any) {
    if (
      this.dialogData.hasOwnProperty('multiple') &&
      !this.dialogData['multiple']
    ) {
      const previousSelectedImageIndex = this.simpoImageList.findIndex(
        (img: any) => img.selected
      );

      if (previousSelectedImageIndex > -1) {
        this.simpoImageList[previousSelectedImageIndex].selected = false;
        delete this.selectedImages[this.simpoImageList[previousSelectedImageIndex].imageId]
      }
    }

    simpoImage.selected = true
    // const blurhash = (await this.generateBlurhash(simpoImage)).hash;
    const image: Media = {
      businessId: this.businessId,
      uniqueId: simpoImage.imageId,
      blurHash: '',
      assets: [{
        width: 0,
        height: 0,
        blurhash: '',
        url: simpoImage.url,
      }],
      author: null,
      media: MediaSource.DEVICE,
      description: '',
      altDescription: simpoImage.altText,
      selected: false
    };
    this.selectImage(image)
  }
  // uploadImages(event: any)
  // {
  //   let files = event.target.files;

  //   const maximumFileSize = 4 * 1024 * 1024; // Max is 4mb

  //   for (let file of files) {
  //     // File size is in bytes
  //     if (maximumFileSize < file.size) {
  //       this.templateService.openSnackbar('Maximum file size limit is 4MB');
  //       continue;
  //     }

  //     const reader = new FileReader();

  //     const selectedImage = new Image();

  //     const image: Media = {
  //       businessId: this.businessId,
  //       uniqueId: file.name + '_' + file.size,
  //       blurHash: null,
  //       assets: [
  //         {
  //           width: 0,
  //           height: 0,
  //           url: '',
  //         },
  //       ],
  //       author: null,
  //       media: MediaSource.DEVICE,
  //       description: file.name,
  //       altDescription: file.name,
  //       selected: this.selectedImages.hasOwnProperty(file.name),
  //       file: file,
  //     };

  //     reader.onload = (response: any) => {
  //       image.assets[0].url = response.target.result;
  //       selectedImage.src = image.assets[0].url;

  //       this.uploadedImagesList = [image, ...this.uploadedImagesList];
  //       this.imagesList = [...this.uploadedImagesList];
  //     };

  //     //Update the File Height and Width.
  //     selectedImage.onload = function () {
  //       image.assets[0].height = selectedImage.height;
  //       image.assets[0].width = selectedImage.width;
  //     };

  //     if (this.imagesList.find((img: Media) => img.description === file.name))
  //       continue;

  //     reader.readAsDataURL(file);
  //   }
  // }
  removeUploadedPhoto(removedImage: Media) {
    const imageIndex = this.imagesList.findIndex(
      (img: Media) => img.uniqueId === removedImage.uniqueId
    );

    this.imagesList.splice(imageIndex, 1);

    delete this.selectedImages[removedImage.uniqueId];

    this.uploadedImagesList = [...this.imagesList];
  }
  async insertImages() {
    if (!Object.keys(this.selectedImages).length) return;
    console.log("SELECTED IMAGE", this.selectedImages);

    this.showButtonLoader = true;

    const images: { [key: string]: string } = {};

    if (this.uploadedImagesList.length > 0) {
      for (let image of this.uploadedImagesList) {
        const response: any =
          await this.imageUploadService.uploadPageScreenShots(
            image.file,
            'library-media'
          );

        images[image.uniqueId] = response.Location;
      }
    }

    // for (let image of this.imagesList) {
    //   if (images.hasOwnProperty(image.uniqueId)) {
    //     image.assets[0].url = images[image.uniqueId];
    //     image.assets[0].blurhash = (await this.generateBlurhash(image.assets[0].url)).hash;
    //   }
    // }
    const imageProcessingPromises = this.imagesList.map(async (image) => {
      console.log("Count");
      if (images.hasOwnProperty(image.uniqueId)) {
        image.assets[0].url = images[image.uniqueId];
        image.assets[0].blurhash = "";
      }
    });
    // await Promise.all(imageProcessingPromises);
    const selectedImagesList = Object.values(this.selectedImages);
    console.log('selected image', selectedImagesList)
    console.log('selected image is', this.selectedImages)

    const sendingMediaRequest = {
      // Already saved images should not be added again
      data: selectedImagesList.filter(
        (img: Media) => !this.libraryImagesList.hasOwnProperty(img.uniqueId)
      ),
    };

    if (sendingMediaRequest.data.length > 0) {
      this.templateService
        .saveImagesToBusinessLibrary(sendingMediaRequest)
        .subscribe({
          next: () => {
            // this._dialogRef.close(sendingMediaRequest.data);
            this._dialogRef.close(selectedImagesList);
            this.templateService.openSnackbar('Images added to library');
          },
          error: () => {
            this.showButtonLoader = false;
            this.templateService.openSnackbar(
              'Something went wrong, please try again'
            );
          },
        });
    } else {
      this._dialogRef.close(selectedImagesList);
    }
  }

  private async generateBlurhash(imageUrl: string): Promise<{
    hash: string;
    width: number;
    height: number;
  }> {
    const loadedImageObject = await new Promise<HTMLImageElement>(
      (resolve, reject) => {
        const img = new Image();
        img.setAttribute('crossOrigin', '');
        img.onload = () => resolve(img);
        img.onerror = (...args) => reject(args);
        img.src = imageUrl;
      }
    );

    const canvas = document.createElement('canvas');
    canvas.width = loadedImageObject.width;
    canvas.height = loadedImageObject.height;
    const context = canvas.getContext('2d');
    context?.drawImage(loadedImageObject, 0, 0);
    const imageData = context?.getImageData(0, 0,
      loadedImageObject.width,
      loadedImageObject.height
    );

    if (!imageData) {
      throw Error('Could not render an image.');
    }

    return {
      hash: encode(imageData.data, imageData.width, imageData.height, 5, 5),
      width: loadedImageObject.width,
      height: loadedImageObject.height,
    };
  }
}
