import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CreateBlogsComponent } from './master-config-components/micro-apps/website/edit-website/website-generationcomponent/blogs/create-blogs/create-blogs.component';
import { SigninSignupComponent } from './our-components/signin-signup/signin-signup/signin-signup.component';
import { PreviewComponent } from './preview/preview.component';
import { AuthGuard } from './services/auth.guard';
import { RestPasswordComponent } from './our-components/signin-signup/rest-password/rest-password.component';
import { CreateMeetingComponent } from './master-config-components/micro-apps/crm/view-lead-details/lead-detail-component/create-components/create-meeting/create-meeting.component';
import { CreateCallComponent } from './master-config-components/micro-apps/crm/view-lead-details/lead-detail-component/create-components/create-call/create-call.component';
import { Title } from '@angular/platform-browser';
import { AddCustomizationsComponent } from './master-config-components/micro-apps/crm/view-lead-details/lead-detail-component/create-components/add-customizations/add-customizations.component';
import { V2EditorComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/v2-editor/v2-editor.component';
import { HomeGuard } from './services/home.guard';
import { WebsitePreviewComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/website-preview/website-preview.component';
import { ViewInvoiceReceiptComponent } from './website-generation/view-invoice-receipt/view-invoice-receipt.component';
import { V3WebsiteGenerationComponent } from './website-generation/v3-website-generation/v3-website-generation.component';
import { V3EditorComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/v3-editor/v3-editor.component';
import { V3GlobalStylesComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/editor-components/v3-global-styles/v3-global-styles.component';
import { ViewRegistrationFormComponent } from './master-config-components/micro-apps/es-sub-modules/registrations/app-setting/settings-modules/view-registration-form/view-registration-form/view-registration-form.component';
import { ViewAdmissionFormComponent } from './master-config-components/micro-apps/es-sub-modules/student-management/app-settings/sm-setting-modules/view-admission-form/view-admission-form/view-admission-form.component';
import { VerifyPaymentComponent } from './master-config-components/micro-apps/es-sub-modules/student-management/verify-payment/verify-payment/verify-payment.component';
import { NewWTypeComponent } from './our-components/new-w-type/new-w-type.component';

const routes: Routes = [
  {
    path: 'select-location',
    loadChildren:()=>import('./our-components/b-type/b-type.module').then(m => m.BTypeModule)
  },
  {
    path: 'website-type',
    // loadChildren :() => import('./our-components/w-type/w-type.module').then(m => m.WTypeModule)
    component : NewWTypeComponent
  },
  {
    path: 'business-name',
    loadChildren:()=>import('./our-components/b-name/b-name.module').then(m=> m.BNameModule)
  },
  {
    path:'preview',
    component:PreviewComponent
  },
  {
    path:'create-meeting',
    component:CreateMeetingComponent
  },
  {
    path:'create-call',
    component:CreateCallComponent
  },
  {
    path:'create-customization',
    component: AddCustomizationsComponent
  },
  {
    path:'list-business',
    loadChildren:()=>import('../app/master-config-components/micro-apps/business/all-business/all-business.module').then(m => m.AllBusinessModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import(
        './master-config-components/master-config-mod/master-config-mod.module'
      ).then((module) => module.MasterConfigModModule),
      canActivate:[AuthGuard]
  },
  // {
  //   path:'masters',
  //   loadChildren:() =>
  //     import(
  //       './master-config-components/master/master.module'
  //     ).then(m => m.MasterModule)
  // },
  {
    path:'signup',
    component:SigninSignupComponent,
    canActivate:[HomeGuard]
  },
  {
    path:'reset-password',
    component:RestPasswordComponent
  },
  {
    path: 'registrationForm',
    component: ViewRegistrationFormComponent
  },
  {
    path: 'admissionForm',
    component: ViewAdmissionFormComponent
  },
  {
    path: 'verify-payment',
    component: VerifyPaymentComponent
  },
  {
    path:'',
    component:SigninSignupComponent,
    canActivate:[HomeGuard]
  },
  // {
  //   path:'',
  //   loadChildren:() => import('./our-components/custom-login-screen/custom-login-screen.module').then(m => m.CustomLoginScreenModule)
  // },
  {
    path:'upload',
    // component:TemplateImagesUploadComponent
    loadChildren:() => import('./our-components/image-upload/image-upload.module').then(m => m.ImageUploadModule)
  },
  {
    path:'create-blog',
    component:CreateBlogsComponent
  },
  {
    path:"stripe-success",
    loadChildren:() => import('./our-components/strip/strip-success/strip-success.module').then(m => m.StripSuccessModule)
  },
  
  {
    path:'stripe-cancel',
    loadChildren:()=>import('./our-components/strip/strip-cancel/strip-cancel.module').then(m => m.StripCancelModule)
  },
  
  // {
  //   path:'v2-generator',
  //   component:V2WebsiteGenerationComponent
  // },
  {
    path:'v3-generator',
    component:V3WebsiteGenerationComponent
  },
  {
    path:'v2-editor',
    component:V2EditorComponent
  },
  {
    path:'website-preview',
    component: WebsitePreviewComponent
  },
  {
    path: 'v3-editor',
    component: V3EditorComponent
  },
  {
    path: 'website-editor',
    component: V3GlobalStylesComponent
  },
  {
    path:'invoice-view-receipt',
    component:ViewInvoiceReceiptComponent
  }

];

@NgModule({
  // imports: [RouterModule.forRoot(routes,{
  //   preloadingStrategy: PreloadAllModules
  // })],
  imports:[RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules })],
  providers:[Title],
  exports: [RouterModule],
})
export class AppRoutingModule {}
