<div class="overflow-hidden parent-container d-flex align-items-center justify-content-center flex-column clr-grey">
    <div class="stepper d-flex align-items-center justify-content-between">
        <div class="step d-flex align-items-center justify-content-center" *ngFor="let step of steps; let i = index"
            [class.active]="currentStep === i + 1" [class.justify-content-start]="i == steps.length - 1">
            <div class="d-flex align-items-center circle-label">
                <div class="circle">{{ i + 1 }}</div>
                <span class="label">{{ step }}</span>
            </div>
            <div *ngIf="i < steps.length - 1" class="line"></div>
        </div>
    </div>
    <div class="px-4 py-5 shadow-lg field-container">
        <!-- Header Section -->
        <div class="d-flex justify-content-center align-items-center mb-6 w-100">
            <div class="icon-container d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none"
                    stroke="url(#grad1)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stop-color="#283E90"/>
                            <stop offset="100%" stop-color="#F000E8"/>
                        </linearGradient>
                    </defs>
                    <rect width="16" height="20" x="4" y="2" rx="2" ry="2"></rect>
                    <path d="M9 22v-4h6v4"></path>
                    <path d="M8 6h.01"></path>
                    <path d="M16 6h.01"></path>
                    <path d="M12 6h.01"></path>
                    <path d="M12 10h.01"></path>
                    <path d="M12 14h.01"></path>
                    <path d="M16 10h.01"></path>
                    <path d="M16 14h.01"></path>
                    <path d="M8 10h.01"></path>
                    <path d="M8 14h.01"></path>
                </svg>
            </div>
        </div>

        <h1 class="mb-2">Tell us about your business</h1>
        <p class="mb-6">
            Please provide information about your business to help us customize your experience.
        </p>

        <!-- Business Type Input -->
        <form>
            <div class="relative">
                <div class="input-wrapper">
                    <div class="input-icon">
                        <i class="lucide lucide-building h-5 w-5 text-gray-400"></i>
                    </div>
                    <mat-form-field class="location-field w-100" appearance="outline">
                        <input type="text" placeholder="Enter Business type" matInput [formControl]="businessForm"
                            [matAutocomplete]="auto1" (ngModelChange)="determineWebsiteType(businessForm.value)" />
                        <button *ngIf="businessForm.value" class="clear-button" (click)="businessForm.setValue('')">
                            <i class="lucide lucide-x h-5 w-5"></i>
                        </button>
                        <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option *ngFor="let option of filteredIndustries | async; index as i"
                                [value]="option.name" (click)="selectBusinessType(option)"
                                (keyup.enter)="selectBusinessType(option)" id="searchValue">
                                {{ option.name | titlecase }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>

            <!-- Website Type Selection -->
            <div class="mt-4 py-2 middle-container d-flex flex-column justify-content-center"
                *ngIf="businessForm.value">
                <p class="text-sm text-gray-700 mb-3">
                    Please select the type of website you need:
                </p>

                <div class="d-flex btn-container align-items-center justify-content-center">
                    <button type="button" class="website-option-btn" [ngClass]="{
                'active-btn': websiteType === 'E_COMMERCE',
                'normal-btn': websiteType !== 'E_COMMERCE'
              }" (click)="handleWebsiteTypeClick('E_COMMERCE')" disabled>
                        <i class="lucide lucide-store mr-2 h-4 w-4"></i>
                        E-commerce
                    </button>

                    <button type="button" class="website-option-btn" [ngClass]="{
                'active-btn': websiteType === 'STATIC',
                'normal-btn': websiteType !== 'STATIC'
              }" (click)="handleWebsiteTypeClick('STATIC')" disabled>
                        <i class="lucide lucide-info mr-2 h-4 w-4"></i>
                        Informative
                    </button>
                </div>

                <p class="text-xs text-gray-500 mt-3">
                    Select the option that best fits your business needs.
                </p>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-5 next-btn-container">
                <button type="submit" [disabled]="!selectedIndustry.subIndustryId" class="next-btn" [ngClass]="{
            'cursor-not-allowed': !selectedIndustry.subIndustryId
          }" (click)="storeBDetails()">
                    Next
                    <i class="lucide lucide-arrow-right ml-2 h-4 w-4"></i>
                </button>
            </div>
        </form>
    </div>
</div>