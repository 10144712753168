import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieLoaderComponent } from 'src/app/shared-module-files/components/lottie-loader/lottie-loader.component';
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { LottiePoppperComponent } from 'src/app/shared-module-files/components/lottie-poppper/lottie-poppper.component';
import { HomeNavBarComponent } from './home-nav-bar/home-nav-bar.component';
import { WebsiteLoaderComponent } from 'src/app/shared-module-files/website-loader/website-loader.component';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}



@NgModule({
  declarations: [
    LottieLoaderComponent,
    LottiePoppperComponent,
    HomeNavBarComponent,
    WebsiteLoaderComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports:[
    LottieLoaderComponent,
    LottiePoppperComponent,
    HomeNavBarComponent,
    WebsiteLoaderComponent
  ]
})
export class CommonComponentModuleModule { }
