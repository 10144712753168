<ng-container *ngIf="currentBlogSection === 'MAIN'; then BlogCreateArea; else MetaCreateArea"></ng-container>

<ng-template #BlogCreateArea>
  <section class="blog-create-area">
    <div class="header-main-section">
      <div class="header-inner">
        <div class="header-text">
          Blog Settings
        </div>
        <div class="cross-image d-flex justify-content-end">
          <mat-icon (click)="closeDialog()" class="d-flex align-items-center justify-content-center">close</mat-icon>
        </div>
      </div>
    </div>
    <div class="content-fields">
      <div class="field">
        <div class="lead-heading">Post Title <sup>*</sup></div>
        <div class="input-container">
          <input matInput placeholder="Enter Title" [(ngModel)]="blog.postTitle" />
        </div>
      </div>
      <div class="field">
        <div class="lead-heading">Post Summary <sup>*</sup></div>
        <div class="input-container">
          <textarea matInput placeholder="Enter short description about post" [(ngModel)]="blog.postSummary"></textarea>
        </div>
      </div>
      <div class="field">
        <div class="lead-heading">Tags</div>
        <mat-form-field class="custom-mat-form-field simpo-mat-chip" appearance="outline" [formControl]="tagControl">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of filteredTags" [value]="item.tagName" (click)="addTag(item.tagName, TagInput)">
              {{ item.tagName }}
            </mat-option>
            <mat-option *ngIf="tagControl.value && showAddTagOption" (click)="addTagThroughApi(TagInput)">
              <div class="text-secondary">Add <span class="text-dark">"{{ tagControl.value }}"</span></div>
            </mat-option>
          </mat-autocomplete>
          <mat-chip-grid #tagsChipGrid aria-label="Tags selection">
            <mat-chip-row class="mat-chip" *ngFor="let tag of tags" (removed)="removeTag(tag)">
              <strong>{{ tag }}</strong>
              <button matChipRemove [attr.aria-label]="'remove ' + tag">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input placeholder="Add Tag..." [formControl]="tagControl" [matChipInputFor]="tagsChipGrid"
            [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addNewTag($event)" #TagInput style="width: 100%; overflow-x: scroll;"/>
          </mat-chip-grid>
        </mat-form-field>
      </div>
      <div class="field">
        <div class="lead-heading">Author</div>
        <mat-form-field class="custom-mat-form-field simpo-mat-chip" appearance="outline">
          <mat-chip-grid #authorChipGrid aria-label="Author selection">
            <mat-chip-row class="mat-chip" *ngFor="let author of authors" (removed)="removeAuthor(author)">
              <strong>{{ author }}</strong>
              <button matChipRemove [attr.aria-label]="'remove ' + author">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input
              placeholder="Pick author"
              aria-label="Author"
              [formControl]="authorControl"
              [matAutocomplete]="auto1"
              [matChipInputFor]="authorChipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addNewAuthor($event)"
              #AuthorInput  style="width: 100%; overflow-x: scroll;"
            >
          </mat-chip-grid>
          <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" >
            <mat-option *ngFor="let option of filteredAuthors" [value]="option.authorName" (click)="addAuthor(option.authorName, AuthorInput)">
              {{option.authorName}}
            </mat-option>
            <mat-option *ngIf="authorControl.value && showAddAuthorOption" (click)="addAuthorThroughApi(AuthorInput)">
              <div class="text-secondary">Add <span class="text-dark">"{{ authorControl.value }}"</span></div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- <div class="field">
        <div class="lead-heading">Author Email Id</div>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input matInput placeholder="Enter author email" [(ngModel)]="authorEmail" />
        </mat-form-field>
      </div> -->
      <div class="meta-card" (click)="toggleMetaCardView()">
        <div class="meta-text">
          <div class="lead-heading">
            Meta Data
            <span matBadge="15" matBadgeColor="primary" [matTooltip]="blog.blogMeta.metaTitle && blog.blogMeta.metaDescription.content
                ? 'Cool, meta information added.' : 'Add more content for better reach.'"
              *ngIf="blog.blogMeta.metaTitle || blog.blogMeta.metaDescription.content">
              <mat-icon class="success" color="primary">check_circle</mat-icon>
            </span>
          </div>
          <div class="metadislay-arrow">
            <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/249053c1742889864865arrow-right.png"
              alt="right-arrow">
          </div>
        </div>
        <div class="sub-metacardtext">
          Extra Content for search engines
        </div>
      </div>

      <div class="buttons-section">

        <button type="button" (click)="saveBlogSettings()" *ngIf="!data?.edit"
          [disabled]="blog.postTitle === '' || blog.postSummary === ''">
          <span *ngIf="addLoader"><i class="fas fa-spinner fa-spin "></i>&nbsp;Loading</span>
          <span *ngIf="!addLoader"> Add </span>
        </button>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #MetaCreateArea>
  <section class="blog-create-area">
    <div class="header-main-section">
      <div class="header-inner">
        <div class="header-text d-flex align-items-center cursor-pointer" (click)="toggleMetaCardView()">
          <mat-icon style="font-size: 26px;">keyboard_arrow_left</mat-icon> Blog Settings
        </div>
        <button class="done-btn" (click)="saveMetaInformation()">Done</button>
      </div>
    </div>
    <form class="content-fields mb-1 d-flex align-items-center flex-column" [formGroup]="metaForm">
      <div class="field mb-1">
        <div class="lead-heading">Meta Title</div>
        <div class="input-container">
          <input matInput placeholder="Add Title" formControlName="metaTitle" [(ngModel)]="blog.blogMeta.metaTitle" />
          <mat-hint style="min-width: max-content;" class="text-secondary">Recommended: 60 characters. You've used {{
            blog.blogMeta.metaTitle.length }}</mat-hint>
        </div>
      </div>
      <div class="field mb-1">
        <div class="lead-heading">Meta Description</div>
        <div class="input-container">
          <textarea matInput placeholder="Meta description" formControlName="metaDescription"
            [(ngModel)]="blog.blogMeta.metaDescription.content"></textarea>
          <mat-hint style="min-width: max-content;" class="text-secondary">Recommended: 140 characters. You've used {{
            blog.blogMeta.metaDescription.content.length }}</mat-hint>
        </div>
      </div>
      <div class="field mb-2">
        <div class="lead-heading">Search Engine Result Preview</div>
        <div class="meta-card w-100">
          <!-- <div class="d-flex align-items-center mb-2"> -->
          <!-- <div class="me-2"><img src="../../../../../../../../assets/images/common/google.svg" alt="Google"></div>
            <div class="d-flex justify-content-end align-items-center flex-grow-1 py-2 px-3 google-search rounded-pill">
              <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="Search">
            </div> -->
          <!-- <div class="google_img">
              <img src="./../../../../../assets/images/common/google.svg" alt="google">
          </div>
          <mat-form-field class="google_search" appearance="outline">
              <input matInput formControlName="metaTitle" [(ngModel)]="blog.blogMeta.metaTitle" />
              <mat-icon matSuffix>search</mat-icon>
          </mat-form-field> -->
          <!-- </div> -->
          <div class="google_searchbox">
            <div class="google_img">
              <img src="./../../../../../assets/images/common/google.svg" alt="google">
            </div>
            <mat-form-field class="google_search" appearance="outline">
              <input matInput formControlName="metaTitle" [(ngModel)]="blog.blogMeta.metaTitle" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <p class="text-secondary mx-0 my-0" style="font-size: 12px; padding-top: 5%;" *ngIf="businessWebsiteUrl">{{
            businessWebsiteUrl+'/blogs' + ' > ' + 'home' }}</p>
          <!-- <h6 class="text-primary mx-0 my-0">{{ blog.blogMeta.metaTitle ? blog.blogMeta.metaTitle.substring(0, 40) + (blog.blogMeta.metaTitle.length > 39 ? '...' : '') : 'Meta title' }}</h6>
          <p class="text-dark mx-0 my-0" style="font-size: 14px;">
            {{ blog.blogMeta.metaDescription.content ? blog.blogMeta.metaDescription.content.substring(0, 40) + (blog.blogMeta.metaDescription.content.length > 39 ? '...' : '') : 'Meta Description' }}
          </p> -->
        </div>
      </div>
    </form>
  </section>

  <!-- <section class="blog-create-area" *ngIf="scrWidth < 475">
    <div class="header-main-section">
      <div class="header-inner">
        <div class="header-text d-flex align-items-center cursor-pointer" (click)="toggleMetaCardView()">
          <mat-icon style="font-size: 26px;">keyboard_arrow_left</mat-icon> Blog Settings
        </div>
      </div>
    </div>
    <form class="content-fields mb-1" [formGroup]="metaForm">
      <div class="field mb-1">
        <div class="lead-heading">Meta Title</div>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Add Title" formControlName="metaTitle" [(ngModel)]="blog.blogMeta.metaTitle" />
          <mat-hint style="min-width: max-content;">Recommended: 60 characters. You've used {{
            blog.blogMeta.metaTitle.length }}</mat-hint>
        </mat-form-field>
      </div>
      <div class="field mb-1">
        <div class="lead-heading">Meta Description</div>
        <mat-form-field appearance="outline">
          <textarea matInput placeholder="Meta description" formControlName="metaDescription"
            [(ngModel)]="blog.blogMeta.metaDescription.content" style="margin-top: 40px;"></textarea>
          <mat-hint style="min-width: max-content;">Recommended: 140 characters. You've used {{
            blog.blogMeta.metaDescription.content.length }}</mat-hint>
        </mat-form-field>
      </div>
      <div class="field mb-2">
        <div class="lead-heading">Search Engine Result Preview</div>
        <div class="meta-card w-100">
          <div class="google_searchbox">
            <div class="google_img">
              <img src="./../../../../../assets/images/common/google.svg" alt="google">
            </div>
            <mat-form-field class="google_search" appearance="outline">
              <input matInput formControlName="metaTitle" [(ngModel)]="blog.blogMeta.metaTitle" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <p class="text-secondary mx-0 my-0" style="font-size: 12px;" *ngIf="businessWebsiteUrl">{{
            businessWebsiteUrl+'/blogs' + ' > ' + 'home' }}</p>
          <h6 class="text-primary mx-0 my-0">{{ blog.blogMeta.metaTitle ? blog.blogMeta.metaTitle.substring(0, 40) +
            (blog.blogMeta.metaTitle.length > 39 ? '...' : '') : 'Meta title' }}</h6>
          <p class="text-dark mx-0 my-0" style="font-size: 14px;">
            {{ blog.blogMeta.metaDescription.content ? blog.blogMeta.metaDescription.content.substring(0, 40) +
            (blog.blogMeta.metaDescription.content.length > 39 ? '...' : '') : 'Meta Description' }}
          </p>
        </div>
      </div>
    </form>
    <button class="mobile-button" (click)="saveMetaInformation()">Done</button>
  </section> -->
</ng-template>