import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConstantMaterialModule } from './material_module/constant-material.module';
import { SigninSignupComponent } from './our-components/signin-signup/signin-signup/signin-signup.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { PreviewComponent } from './preview/preview.component';
import { ForgotPasswordComponent } from './our-components/signin-signup/forgot-password/forgot-password.component';
import { RestPasswordComponent } from './our-components/signin-signup/rest-password/rest-password.component';
import { NgSwitcheryModule } from 'angular-switchery-ios';
import { ChangePasswordComponent } from './our-components/signin-signup/change-password/change-password.component';
import { LazyImgDirective } from './lazy-img-directive.directive';
import { CustomSnackbarComponent } from './helper-components/custom-snackbar/custom-snackbar.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider
} from '@abacritt/angularx-social-login';
import { SingupPopupComponent } from './our-components/signin-signup/singup-popup/singup-popup.component';
import { FormsModule } from '@angular/forms';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ViewInvoiceReceiptComponent } from './website-generation/view-invoice-receipt/view-invoice-receipt.component';
import { GlobalInvoiceDownloadComponent } from './website-generation/global-invoice-download/global-invoice-download.component';
import { GenerateWebsitePopupComponent } from './our-components/generate-website-popup/generate-website-popup.component';
import { V3WebsiteGenerationComponent } from './website-generation/v3-website-generation/v3-website-generation.component';
import { CommonComponentModuleModule } from './our-components/common-component-module/common-component-module.module';
import { DatePipe } from '@angular/common';
import {API_URL, ECOMMERCE_URL} from 'simpo-component-library';
import { NewWTypeComponent } from './our-components/new-w-type/new-w-type.component'

@NgModule({
  declarations: [
    AppComponent,
    SigninSignupComponent,
    PreviewComponent,
    ForgotPasswordComponent,
    RestPasswordComponent,
    ChangePasswordComponent,
    LazyImgDirective,
    CustomSnackbarComponent,
    SingupPopupComponent,
    ViewInvoiceReceiptComponent,
    GlobalInvoiceDownloadComponent,
    GenerateWebsitePopupComponent,
    V3WebsiteGenerationComponent,
    NewWTypeComponent
  ],
  imports: [
    CommonComponentModuleModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    AppRoutingModule,
    ConstantMaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxBarcodeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU',
      libraries: ['places', 'geometry'],
    }),
    NgSwitcheryModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
  ],
  providers: [
    DatePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '675780867172-62em2u33p7vmv616ofqmej9n52tged8v.apps.googleusercontent.com', {oneTapEnabled:false, prompt:'consent'}
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {provide: API_URL, useValue: environment.baseUrl},
    {provide: ECOMMERCE_URL, useValue: environment.ecommerceWithoutGateway}
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
