import { Component, HostListener } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-anime-loader',
  templateUrl: './anime-loader.component.html',
  styleUrls: ['./anime-loader.component.scss']
})
export class AnimeLoaderComponent {
  
  constructor(){
    this.getScreenSize();
  }


  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
  options: AnimationOptions = {
    path: './../../../../assets/animations/animation.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    const gradientColors = [
      { offset: 0, color: [40, 62, 144] }, // #283E90
      { offset: 1, color: [240, 0, 232] }, // #F000E8
    ];

    // Function to interpolate gradient colors
    const getGradientColor = (progress: number): [number, number, number] => {
      const color1 = gradientColors[0].color;
      const color2 = gradientColors[1].color;
      const r = Math.round(color1[0] + (color2[0] - color1[0]) * progress);
      const g = Math.round(color1[1] + (color2[1] - color1[1]) * progress);
      const b = Math.round(color1[2] + (color2[2] - color1[2]) * progress);
      return [r / 255, g / 255, b / 255]; // Normalize to 0-1
    };

    // Update the animation colors dynamically
    animationItem.addEventListener('enterFrame', () => {
      const progress = animationItem.currentFrame / animationItem.totalFrames;
      const color = getGradientColor(progress);

      // Traverse through the animation elements
      animationItem.renderer.elements.forEach((element) => {
        if (element.isLoaded && element.data?.nm === 'Stroke 1') {
          // Update the stroke color
          element.strokeColor.setValue(color);
        }
      });
    });
  }
}
