import { EventEmitter, Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Media } from './template-constants';
import { StorageKeys } from '../shared-module-files/simpo.constant';
// import { Media } from 'aws-sdk/clients/transcribeservice';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private apiUrl = 'https://api.unsplash.com';
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private _bottomSheet: MatBottomSheet
  ) { }

  // getPageData(){
  //   return this.http.get(
  //     environment.baseUrl+`business/template/page`
  //   )
  // }
   // Unsplash
   searchPhotosInUnsplash(
    searchedText: string,
    page: number = 1,
    size: number = 10
  ) {
    const header = new HttpHeaders({
      Authorization: `Client-ID ${environment.unsplash.UNSPLASH_API_TOKEN}`,
    });

    return this.http.get(
      `https://api.unsplash.com/search/photos?page=${page}&per_page=${size}&query=${searchedText}`,
      {
        headers: header,
      }
    );
  }

  // Shutterstock
  searchPhotosInShutterstock(
    searchedText: string,
    page: number = 1,
    size: number = 10
  ) {
    const header = new HttpHeaders({
      Authorization: `Bearer ${environment.shutterstock.SHUTTERSTOCK_API_TOKEN}`,
    });

    return this.http.get(
      `https://api.shutterstock.com/v2/images/search?page=${page}&per_page=${size}&query=${searchedText}`,
      {
        headers: header,
      }
    );
  }

  // Media library
  saveImagesToBusinessLibrary(mediaRequest: { data: Media[] }) {
    return this.http.post(
      environment.serverIp + `library/image/library/save`,
      mediaRequest
    );
  }

  getSavedImagesInLibrary(
    businessId: string,
    platform: MediaSource | string,
    pageNo: number,
    size: number
  ) {
    return this.http.get(
      environment.serverIp +
      `library/image/library/list/paged?pageNo=${pageNo}&size=${size}&businessId=${businessId}&platform=${platform}`
    );
  }
  // unsplash
  regenerateContentPhotos(query: string) {
    const headers = new HttpHeaders(
      {
        'Authorization': 'Client-ID ${this.accessKey}'
      }
    );
    const url = `${this.apiUrl}/search/photos?query=${query}`;
    return this.http.get(url, { headers })
  }
  debounce(fn, duration: number = 500) {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    return function () {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => fn(), duration);
    };
  }

  getTempDataById(){
    return this.http.get(
      environment.baseUrl+`business/template/1eda0948-7f25-6160-80d4-b99e60e79e76`
    )
  }

  getTemplateDataByRouteName(templateName:any){
    return this.http.get(
      environment.baseUrl+`business/template/route?routeName=${templateName}`
    )
  }

  postPageData(data:any){
    return this.http.post(
      environment.baseUrl+`business/template/page`,data
    )
  }
  getPageData(templateId:any,pageId:any){
    return this.http.get(
      environment.baseUrl+`business/template/page?templateId=${templateId}&pageId=${pageId}`
    )
  }

  getListOfWebsites(){
    return this.http.get(
      environment.baseUrl+`business/template?size=100&page=0`
    )
  }
  getWebsitesBasedOnBusiness(businessId:any){
    return this.http.get(
      environment.baseUrl+`business/business/website?size=100&page=0&businessId=${businessId}`
    )
  }
  getWebsitesBasedOnWithoutBusiness(){
    return this.http.get(
      environment.baseUrl+`business/business/website?size=100&page=0`
    )
  }

  updateWebsiteDomain(data:any){
    return this.http.put(
      environment.baseUrl+`business/business/website`,data
    )
  }
  attachWebsiteToBusiness(data:any){
    return this.http.put(
      environment.baseUrl+`business/business/template`,data
    )
  }
  getTemplateDataByBuisiness(businessId:any,tempId:any){
    return this.http.get(
      environment.baseUrl+`business/template/website?businessId=${businessId}&templateId=${tempId}`
    )
  }

  updateElements = new EventEmitter<any>();

  updateElementsData= new EventEmitter<any>();

  getTemplateDetails = new EventEmitter<any>();

  scrollToComponent = new EventEmitter<any>();

  updatePageName = new EventEmitter<any>();

  componentRegenration = new EventEmitter<any>();

  editorSave = new EventEmitter<any>();

  getAllBlogs(){
    return this.http.get(
      environment.baseUrl+`business/blog/getAll`
    )
  }



  //Website Builder API Integrations


  //get all business types
  getAllBusinessTypes(){
    return this.http.get(
      environment.serverIp+`master/business/industry?size=10&page=0`
    )
  }
  getSubIndustryTypes(industryId :any){
    return this.http.get(
      environment.serverIp+`master/business/industry/sub/` + industryId
    )
  }
  getAllBusinessName(subCategoryId:any){
    return this.http.get(
      environment.serverIp+`master/business/name/${subCategoryId}?size=100&page=0`
    )
  }


  getTemplateDataByBCId(bcId:any){
    return this.http.get(
      environment.baseUrl + `business/template-content/get/${bcId}`
    )
  }
  intiateTemplateData(data:any){
    return this.http.post(
      environment.baseUrl+`business/website/create`,data
    )
  }
  getTemplateDataByTemplateId(tempId:any){
    return this.http.get(
      environment.baseUrl+`business/website/?websiteId=${tempId}`
    )
  }
  updateTemplateData(data:any){
    return this.http.post(
      environment.baseUrl+`business/website/create`,data
    )
  }

  getBusinessCategoryImages(subIndustryId:any){
    return this.http.get(
      environment.serverIp+`master/business/images?subIndustryId=`+subIndustryId
    )
  }

  openDialogWithData(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw'
    });
  }

  openDialog(componentName:any, height:any, width:any, dataModel:any , panelClass? : any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw',
      panelClass : panelClass
    });
  }

  openDialogWithClass(componentName:any, height:any, width:any, dataModel:any,panelClass:string) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw',
      panelClass:panelClass,
    });
  }

  openDialogWithMaxHeight(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw',
      maxHeight:'calc(100vh - 90px)'
    });
  }

  openDialogForEditorSmallScreen(componentName:any, height:any, width:any, dataModel:any){
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth:'100vw',
      position:{
        right:'0px',
        bottom:'0px'
      }
    });
  }
  openSnackbar(message: string) {
    this.snackbar.open(message, 'Ok', {
      duration: 1500,
    });
  }
  openSnack(message:any, action:any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openBottomSheet(componentName: any, dataModel: any, panelClass: string) {
    return this._bottomSheet.open(componentName, {
      data: dataModel,
      panelClass: panelClass
    });
  }

  createBusiness(data:any){
    return this.http.post(
    environment.baseUrl+`business/business`,data
    )
  }

  updateBusiness(data:any){
    return this.http.put(
      environment.baseUrl+`business/business`,data
    )
  }


  getBusiness(pageNo:any,size:any){
    return this.http.get(
      environment.baseUrl+`business/business?size=${size}&page=${pageNo}`
    )
  }
  searchBusiness(size:any,pageNo:any,searchParameter:any){
    return this.http.get(
      environment.baseUrl+`business/business?size=${size}&page=${pageNo}&text=${searchParameter}`
    )
  }

  getBusinessDetailsById(id:any){
    return this.http.get(
      environment.baseBusinessUrl+`business/${id}`
    )
  }


  updatePage = new EventEmitter<any>();
  editComponent = new EventEmitter<any>();

  //new Apis
  // regenerateComponentCategory(componentCategoryId:any,subBusinessCategoryId:any){
  //   return this.http.get(
  //     environment.baseUrl+`adminmaster/template/builder/component?categoryId=${componentCategoryId}&subIndustryId=${subBusinessCategoryId}`
  //   )
  // }

  regenerateComponentCategoryWithComponentId(componentCategory:any,businessCategoryId:any,componentId:any,componentName:any){
    return this.http.get(
      environment.baseUrl+`business/web-content/business/components/regenerate/${componentCategory}?businessCategoryId=${businessCategoryId}&componentId=${componentId}&componentName=${componentName}`
    )
  }

  contentChangeCategory(componentName:any,businessCategoryId:any){
    return this.http.get(
      environment.baseUrl+`business/web-content/business/components/contentChange/${componentName}?businessCategoryId=${businessCategoryId}`
    )
  }



  //new components and content and template api's from chaithanya sir

  getTemplateStructure(subCategoryId:any){
    return this.http.get(
      environment.serverIp+`master/template?subIndustryId=${subCategoryId}`
    )
  }

  getComponentsData(componentId:any,componentCategoryId:any,subBusinessCategoryId:any){
    if(componentId === null){
      return this.http.get(
        environment.serverIp+`master/template/builder/component?categoryId=${componentCategoryId}&subIndustryId=${subBusinessCategoryId}`
      )
    }
    else{
      return this.http.get(
        environment.serverIp+`master/template/builder/component?componentId=${componentId}&categoryId=${componentCategoryId}&subIndustryId=${subBusinessCategoryId}`
      )
    }
  }
  regenerateComponents(componentCategoryId:any,subIndustryId:any,componentId:any,contentId:any){
    if(contentId != null){
      return this.http.get(
        environment.serverIp+`master/template/builder/component/regenerate?categoryId=${componentCategoryId}&subIndustryId=${subIndustryId}&componentId=${componentId}&contentId=${contentId}`
      )
    }
    else{
      return this.http.get(
        environment.serverIp+`master/template/builder/component/regenerate?categoryId=${componentCategoryId}&subIndustryId=${subIndustryId}&componentId=${componentId}`
      )
    }
  }
  changeContent(categoryId:any,subIndustryId:any,contentId:any){
    return this.http.get(
      environment.serverIp+`master/template/builder/component/content?categoryId=${categoryId}&subIndustryId=${subIndustryId}&contentId=${contentId}`
    )
  }
  mergedIndustrySubIndustry(){
    return this.http.get(
      environment.serverIp+`master/business/industry/sub?size=1000&page=0`
    )
  }


  getSubIndustryByStatus(pageNo:number,size:number,status?:boolean){
    if(status){
      return this.http.get(
        environment.serverIp+`master/business/industry/sub?size=${size}&page=${pageNo}&active=${status}`
      )
    } else {
      return this.http.get(
        environment.serverIp+`master/business/industry/sub?size=${size}&page=${pageNo}`
      )
    }
  }
  getSubIndustry(pageNo: number, size: number)
  {
    return this.http.get(environment.serverIp+`master/business/industry/sub/v2?size=${size}&page=${pageNo}`)
  }

  getAllSubIndustries(){
    return this.http.get(environment.baseUrl + `admin/master/business/industry/sub?size=100&page=0&active=true`)
  }
  contentContentChangeCategory(componentName:any,businessCategoryId:any,categoryId:any){
    return this.http.get(
      environment.baseUrl+`business/web-content/business/components/contentChange/${componentName}?businessCategoryId=${businessCategoryId}&ComponentId=${categoryId}`
    )
  }


  getBlogsByBuisnessId(businessId: any , blogStauts : any) {
    return this.http.get(
      environment.baseUrl + `business/blog/businessId/${businessId}?blogStatus=${blogStauts}`
    )
  }

  getBlogsBySubInstryId(subIndustryId:any){
    return this.http.get(
      environment.serverIp+`blog/getBlog/${subIndustryId}`
    )
  }

  getMasterBlogById(blogId:any){
    return this.http.get(
      environment.serverIp+`admin/blog/${blogId}`
    )
  }

  getTemplateCategory(){
      return this.http.get(
        environment.serverIp+`master/template/category?size=1000&page=0`
      )
  }

  //v2 integrations


  getWebsite(id:any){
    return this.http.get(
      environment.baseUrl+`business/website/v2?websiteId=${id}`
    )
  }

  getWebsiteWithAllPages(id:any){
    return this.http.get(
      environment.baseUrl+`business/website/v2/all?websiteId=${id}`
    )
  }

  getWebsiteWithWebCode(webCode:any){
    return this.http.get(
      environment.baseUrl+`business/website/v2/getBy/websiteCode?websiteCode=${webCode}`
    )
  }

  generateWebsite(data:any){
    return this.http.post(
      environment.serverIp+`master/website/v3`,data
    )
  }

  updateWebsiteData(data:any){
    return this.http.put(
      environment.baseUrl+`business/website/v2/`,data
    )
  }

  regenerateComponent(data){
    return this.http.get(
      environment.serverIp+`master/template/v2/component/regenerate?websiteId=${data.templateId}&index=${data.index}&subIndustryId=${data.subIndustryId}`
    )
  }

  changeComponentContent(data){
    return this.http.get(
      environment.serverIp+`master/template/v2/component/content?websiteId=${data.templateId}&index=${data.index}&subIndustryId=${data.subIndustryId}`
    )
  }

  getLocalComponentWithData(index:any, homePageId:any, componentId:any, subIndustryId:any,pageId:string){
    if(pageId){
      return this.http.get(
        environment.serverIp+`master/template/v2/component/data?index=${index}&homePageId=${homePageId}&componentId=${componentId}&subIndustryId=${subIndustryId}&pageId=${pageId}`
      )
    }else{
      return this.http.get(
        environment.serverIp+`master/template/v2/component/data?index=${index}&homePageId=${homePageId}&componentId=${componentId}&subIndustryId=${subIndustryId}`
      )
    }
  }

  updateNewSection(index:number,pageId:String,data:any){
    return this.http.put(
      environment.serverIp + `master/template/v2/custom-component/data?index=${index}&pageId=${pageId}`, data
    )
  }

  deleteComponent(index:number,pageId:string){
    return this.http.delete(
      environment.serverIp + `master/template/v2/component/delete?index=${index}&pageId=${pageId}`
    )
  }

  updateData(data){
    return this.http.post(
      environment.baseUrl+`business/website/v2/create`,data
    )
  }

  reStylePages(data){
    return this.http.put(
      environment.serverIp+`master/website/style`,data
    )
  }


  getWebsiteData(url:string){
    return this.http.get(
      url,{responseType:'text'}
    )
  }

  getAddress(lat,lng){
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU`
    )
  }

  deletePage(pageId){
    return this.http.delete(
      environment.baseUrl+`business/website/v2?pageId=${pageId}&deleteAll=false`
    )
  }

  getMasterDepartment(pageNo:number,size:number){
    return this.http.get(
      environment.serverIp+`master/hrms/department?pageNo=${pageNo}&size=${size}`
    )
  }

  getMasterDesignation(request:any){
    return this.http.put(
      environment.serverIp+`master/hrms/designation/filter`,request
    )
  }

generateBusinessWebsite(data:any){
  return this.http.post(
    environment.businessWithOutGateway + `business/website/leads`,data
  )
}

  generateLead(data: any){
    return this.http.post(
      environment.businessWithOutGateway + `business/website/leads`,data
    )
  }

  updateLead(subId: any, subName: any, leadId: any){
    return this.http.put(
      environment.businessWithOutGateway + `business/lead/message?leadId=${leadId}&subId=${subId}&subName=${subName}`, ''
    )
  }

//v3 api's for website generation
  generateV3Website(data: any){
    return this.http.put(
      environment.regenerateUrl+`v3/website`, data
    )
  }

  getWebsiteTemplateById(templateId: any){
    return this.http.get(
      environment.baseUrl+`business/v3/website/${templateId}/pages/list`
    )
  }

  updatePageSeo(payload){
    return this.http.put(
      environment.baseBusinessUrl + `v3/business/update/page/seo`,payload
    )
  }

  getWebsiteTemplate(templateId: string, defaultTemplate: boolean = true) {
    if(defaultTemplate)
      return this.http.get(environment.baseUrl + `business/v3/website/${templateId}/pages/list`);
    else
      return this.http.get(environment.baseBusinessUrl + `template-library/${templateId}/website-content/details`)
  }

  getSectionForIndustry(sectionId:any , subIndustryId: any, businessName:string,businessId:any){
    return this.http.get(
      environment.serverIp + `v3/add/section?subIndustryId=${subIndustryId}&componentId=${sectionId}&name=${businessName}&businessId=${businessId}`
    )
  }

  getSectionContent(sectionId:any , subIndustryId: any, businessName: string){
    return this.http.get(
      environment.serverIp + `v3/content/?subId=${subIndustryId}&componentId=${sectionId}&name=${businessName}`
    )
  }

  getComponentById(componentId: any){
    return this.http.get(
      environment.serverIp + `v3/component/?id=${componentId}`
    )

  }

  saveTemplatePage(data: any, isDefaultTemplate: boolean = true, templateId?: any){
    if(isDefaultTemplate) {
      return this.http.post(
        environment.baseUrl + `business/v3/create/website`, data
      )
    } else {
      let payload = {websiteContentV3List : data}
      return this.http.put(
        environment.baseBusinessUrl + `template-library/${templateId}/save`, payload
      )
    }

  }

  getAllThemePallet(){
    return this.http.get(
      environment.serverIp + `master/template/v2/all-themes`
    )
  }

  deleteWebsitePage(pageId: string){
    return this.http.delete(
      environment.baseUrl + `business/v3/delete/page?pageId=${pageId}`
    )
  }
  v3getWebsiteWithWebCode(websiteCode: any)
  {
    return this.http.get(
      environment.baseUrl + `business/v3/websiteCode?websiteCode=${websiteCode}`
    )
  }

  getPageBySubIndustryId(subIndustryId: any, pageId: any) {
    return this.http.get(
      environment.serverIp +
        `v3/random/page?subId=${subIndustryId}&categoryId=${pageId}`
    );
  }

  pageNameCategories(subId: any) {
    return this.http.get(
      environment.baseUrl + `admin/master/template/v2/page/category?subId=${subId}`
    );
  }

  getImageData(data: any){
    return this.http.put(
      environment.baseUrl + `admin/v3/image`, data
    );
  }

  createFolder(data: any){
    return this.http.post(
      environment.baseUrl + `business/folder`, data
    )
  }

  deleteFolder(folderId: any) {
    return this.http.delete(
      environment.baseUrl + `business/folder?folderId=${folderId}`
    )
  }

  getAllFolder(data: any){
    return this.http.put(
      environment.baseUrl + `business/folder`, data
    )
  }

  getNotificationsData(businessId: any, staffId: any) {
    return this.http.get(
      environment.baseBusinessUrl + `notifications/businessId?businessId=${businessId}&staffId=${staffId}`
    );
  }

  notificationSeenStatus(notificationId : any){
    return this.http.put(
      environment.baseBusinessUrl + `notifications/update/seenStatus?notificationId=${notificationId}`, notificationId
    )
  }

  getUnseenDataCount(businessId: any) {
    return this.http.get(
      environment.baseBusinessUrl + `notifications/unseen/get?businessId=${businessId}`
    );
  }

  getFavIcon(businessId: any) {
    return this.http.get(
      environment.baseUrl + `business/v3/get/favicon?businessId=${businessId}`
    )
  }

  updateFavicon(businessId: any, favIcon: any) {
    return this.http.put(
      environment.baseUrl + `business/v3/update/favicon?businessId=${businessId}&favIcon=${favIcon}`, ''
    )
  }

  getTemplateLibrary() {
    const subId = localStorage.getItem(StorageKeys.SUBINDUSTRYID);
    return this.http.get(
      environment.baseUrl + `admin/v3/template/admin/list?subIndustryId=${subId}`
    )
  }

  getBusinessThemes() {
    let businessId = localStorage.getItem('businessId');
    return this.http.get(
      environment.baseBusinessUrl + `template-library/${businessId}`
    )
  }

  addToBusinessLibrary(data) {
    return this.http.put(
      environment.baseUrl + `admin/v3/business-template/library/add`, data
    )
  }

  publishTemplate(templateId: string) {
    return this.http.put(
      environment.baseBusinessUrl + `template-library/${templateId}/publish`, ''
    )
  }

  deleteTheme(templateId: string) {
    return this.http.delete(
      environment.baseBusinessUrl + `template-library/${templateId}`
    )
  }
}
