// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // baseUrl: 'https://api-cmis.tejsoft.com/',
  baseUrl:'https://dev-api.simpo.ai/',
  // baseBusinessUrl : "https://prod-business.cmis.tejsoft.com/",
  baseBusinessUrl : "https://dev-business.simpo.ai/",
  // businessWithOutGateway:'https://prod-business.cmis.tejsoft.com/',
  businessWithOutGateway:'https://dev-business.simpo.ai/',
  // ecommerceWithoutGateway: 'https://prod-ecommerce.simpo.ai/',
  ecommerceWithoutGateway: 'https://dev-ecommerce.simpo.ai/',
  // serverIp: 'https://prod-admin.cmis.tejsoft.com/',
  serverIp: 'https://dev-admin.simpo.ai/',
  // regenerateUrl : 'https://prod-admin.cmis.tejsoft.com/',
  regenerateUrl : 'https://dev-admin.simpo.ai/',
  // redirectingUrl :'https://simpo.ai',
  redirectingUrl :'https://simpo.ai',
  iframeRelated: 'https://app.simpo.ai',
  redirectingOtherSite : 'https://simpo.ai',
  urlEndingPoint :"dev.simpo.ai",
  production: true,
  indexHtmlUrl:`<script src="https://simpo-ai.github.io/blogs/blogs.js"></script>`,
  firebase: {
    apiKey: "AIzaSyB0uWmX7n-GjrZOh0KRXtGG9bgrO0R9fYI",
  authDomain: "beeos-d3a05.firebaseapp.com",
  projectId: "beeos-d3a05",
  storageBucket: "beeos-d3a05.appspot.com",
  messagingSenderId: "264451984597",
  appId: "1:264451984597:web:df34ef53cc1f822b5aa65c",
  measurementId: "G-PCTKBE8GCX"
  },
  imageUploadSize: 1000000,
  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'ap-south-1:952707e8-9a77-4293-b3b2-8a02fb15b371',
          Region: 'ap-south-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'prod-simpo',
        Region: 'ap-south-1',
      },
    },
  },
  componentImageUploading: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'ap-south-1:952707e8-9a77-4293-b3b2-8a02fb15b371',
          Region: 'ap-south-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'prod-simpo',
        Region: 'ap-south-1',
      },
    },
  },

  cmisFileUpload: {
    sasToken: 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2029-12-31T23:45:03Z&st=2024-12-18T15:45:03Z&spr=https,http&sig=Qjj5Xe5KiMxvETm6rP%2FZP31i3xDS9t1QuyuKgcY%2FPC8%3D',
    accountName: 'cmisprod',
    containerName: 'prod-cmis'
  },
  //mapBox for google maps
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibmFpZHUyNTA3IiwiYSI6ImNsZWxjcGlmZTB0ejkzb3BnMndzbmthM2cifQ.BJoCPnd81NYvx_2VUadF2w',
  },

  //goDaddy SSO Key for dev environment
  goDaddy: {
    ssoKey:
      'sso-key 3mM44UcgzgNc8W_PW9i1fz5K8SYbVT1Mzg1i:PW2vd4zTxF8orAqBYD9VXs',
  },

  // Unsplash access key
  unsplash: {
    UNSPLASH_API_TOKEN: 'iMn43HCZ_FLCMWkZgUtMAjt-p-M6vmaB1Z_3fbetmJA',
  },
  // Shutterstock access key
  shutterstock: {
    SHUTTERSTOCK_API_TOKEN:
      'v2/a2FzcG9FMmlOSnJLRUZsa2gzU01GMFlyS1R4T0RqRkYvNDIwNDgwNjc3L2N1c3RvbWVyLzQvSU1KUHlFRTUwc01rTE95NTJXbDJJWVk4MU5UVGtlN2cwcHVoaEptaUhQVXZCSXZHNExrVVdzR3lXWkVaTXQ1WkRTQ2pkZXJOSEV2Q21sRWpFdFdIRWRBSkFqUzlFa25LTkkxd3FaTFdscENSTUpTZEMwdWtBVXFIS0ZWN3Nnekd6eGRnbElUMnhpeG43TXJ3UXR4Mk8xT0R6TzJQVExES3A1cUdDaXB3UGwwOTZEMVk2MEJkRmJoc21feDItdGpiZGFwQVExOWNwLUw4RVN5RnZzY0V6US94cExqQi1YZDhubE9JVEdITlJySTNR' },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
