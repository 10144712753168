import { Component } from '@angular/core';

@Component({
  selector: 'app-website-loader',
  templateUrl: './website-loader.component.html',
  styleUrls: ['./website-loader.component.scss']
})
export class WebsiteLoaderComponent {
  progress = 0;
  messageIndex = 0;
  currentIconIndex = 0;
  currentBgIndex = 0;
  
  loadingMessages = [
    'Analyzing your business profile...',
    'Designing your custom website...',
    'Creating responsive layouts...',
    'Optimizing for search engines...',
    'Adding mobile-friendly features...',
    'Finalizing your website...'
  ];

  icons = ['✨', '💻', '🌍', '🚀'];
  colorSets = [
    ['#6c5ce7', '#ff7675', '#00b894', '#fd79a8'],  // Purple, Red, Green, Pink
    ['#0984e3', '#fdcb6e', '#e17055', '#00cec9'],  // Blue, Yellow, Orange, Teal
    ['#a29bfe', '#ffeaa7', '#55efc4', '#fab1a0'],  // Lavender, Beige, Mint, Peach
    ['#ff9ff3', '#feca57', '#1dd1a1', '#5f27cd']   // Pink, Gold, Aqua, Deep Purple
  ];

  constructor() {}

  ngOnInit(): void {
    this.startProgress();
    this.startIconAnimation();
  }

  startProgress(): void {
    const interval = setInterval(() => {
      if (this.progress < 100) {
        this.progress++;

        if (this.progress === 15) this.messageIndex = 1;
        if (this.progress === 35) this.messageIndex = 2;
        if (this.progress === 55) this.messageIndex = 3;
        if (this.progress === 75) this.messageIndex = 4;
        if (this.progress === 90) this.messageIndex = 5;
      } else {
        clearInterval(interval);
      }
    }, 100);
  }

  startIconAnimation(): void {
    setInterval(() => {
      this.currentIconIndex = (this.currentIconIndex + 1) % this.icons.length;
      this.currentBgIndex = (this.currentBgIndex + 1) % this.colorSets.length;
    }, 1000);
  }

  getMainIcon(): string {
    return this.icons[this.currentIconIndex];
  }

  getCornerIcon(index: number): string {
    return this.icons[(this.currentIconIndex + index + 1) % this.icons.length];
  }

  getMainBg(): string {
    return this.colorSets[this.currentBgIndex][0];
  }

  getCornerBg(index: number): string {
    return this.colorSets[this.currentBgIndex][(index + 1) % this.colorSets[this.currentBgIndex].length];
  }
}