<section class="main_section">
    <div class="section_left_part">
        <div class="popup_title" *ngIf="srcWidth > 475">Create account</div>
        <div class="row_flex" *ngIf="srcWidth < 475">
          <div class="popup_title" *ngIf="srcWidth < 475">Create account</div>
          <div class="close_icon" *ngIf="srcWidth < 475">
            <div (click)="closePopup()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        </div>
        <div class="alrea_account" style="display: flex;justify-content: center;margin-top: 15px;">
            <!-- <div class="acount_border" (click)="openMailLogin()">
                <div class="google_img">
                    <img src="./../../../../assets/images/common/google_icon.svg" alt="google">
                </div>
                <div class="singup_text">
                    Sign up with Google
                </div>
            </div> -->
            <asl-google-signin-button GoogleSigninButtonDirective type='standard' size='large' shape='rectangular'
           id="google_autenticate"></asl-google-signin-button>
        </div>
        <div class="continue-with">
            <hr>
            <div style="color: rgba(107,114,128,var(1));text-align: center;white-space: nowrap;">Or</div>
            <hr>
        </div>
        <div class="signup_body" *ngIf="formType === 'SIGN_UP'">
            <form [formGroup]="signupForm">
              <div class="field">
                <div class="label">Full Name <sup>*</sup></div>
                <mat-form-field appearance="outline" class="taking_input">
                  <input placeholder="Enter Your Full Name" matInput formControlName="name" />
                </mat-form-field>
              </div>
                <div class="fields">
                    <div class="field">
                        <div class="label">Email Address <sup>*</sup></div>
                        <mat-form-field appearance="outline" class="taking_input">
                          <input matInput placeholder="Enter Email Address" formControlName="email" (input)="onEmailChange($event)"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="field">
                    <div class="label">Mobile Number <sup>*</sup></div>
                    <div class="code_number">
                      <div class="country_code">
                        <mat-form-field class="taking_input" appearance="outline">
                          <mat-select formControlName="dialCode" [disabled]="disableCountryCodeDropdown">
                            <mat-option [value]="item.shortCode" *ngFor="let item of countryCodes">
                              {{item.dialingCode}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="mobile_number">
                        <mat-form-field class="taking_input" appearance="outline">
                          <input placeholder="Enter Your Mobile Number" matInput formControlName="mobileNumber" type="number"
                          onKeyPress="if(this.value.length==15) return false;"/>
                        </mat-form-field>
                      </div>
                    </div>
                </div>
                <div class="field">
                    <div class="label">Password <sup>*</sup></div>
                    <mat-form-field class="taking_input" appearance="outline">
                      <input matInput placeholder="Enter Password" [type]="signupHide ? 'password' : 'text'"
                        formControlName="password" minlength="8" maxlength="16" id="focus" (blur)="hide()" />
                      <mat-icon matSuffix (click)="signupHide = !signupHide" style="cursor:pointer"
                        class="eye-icon">{{signupHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                    <div class="password_length">
                      <div style="display:flex;justify-content: space-between;">
                        <div id="capital"
                        class="validation"
                          [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['oneLetterCapital']}">
                          <li>At least One Letter Uppercase</li>
                        </div>
                        <div id="special_character"class="validation"
                          [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['oneSymbolPattern']}">
                          <li>One Special Character</li>
                        </div>
                      </div>
                      <div style="display:flex;justify-content: space-between;">
                        <div id="number"class="validation"
                          [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['oneNumberPattern']}">
                          <li>At least One Numeric</li>
                        </div>
                        <div id="min_length"class="validation"
                          [ngClass]="{'success_text': !signupForm.get('password')?.errors || signupForm.get('password')?.errors?.['minimumLength']}">
                          <li>8 Characters Minimum</li>
                        </div>
                      </div>
                    </div>
                </div>
                <!-- <div class="hint"> -->
                    <!-- Password needs to be at least 8 characters long -->
                <!-- </div> -->
                <!-- <ul class="hint">
                  <li>Atleast 8 characters </li>
                  <li>One uppercase letter</li>
                  <li>One special character</li>
                  <li>Atleast one numeric</li>
              </ul> -->

                <div class="term_conditions">
                    By clicking "Create an account" you agree simpo <span style="cursor: pointer;"
                    (click)="privacyPolicy()">Privacy Policy</span> and <span style="cursor: pointer;"
                    (click)="termsConditions()"> Terms of Service</span>.
                </div>
                <div class="continue_btn">
                    <button (click)="createAnAccount()" [disabled]="!signupForm.valid" *ngIf="!loader" [ngClass]="{'disable-btn': !signupForm.valid}">
                  Create an account
                    </button>
                    <button *ngIf="loader">
                        <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                    </button>
                </div>
            </form>
            <div class="alrea_account">
                Already have an account? <span style="cursor: pointer;" (click)="redirectToSignIn()">Sign
                  In</span>
            </div>
        </div>
        <div class="signin_body" *ngIf="formType === 'LOGIN'">
            <form [formGroup]="signInForm">
                <div class="fields">
                    <div class="field">
                      <div class="label">Email Address <span class="required">*</span> </div>
                      <mat-form-field appearance="outline" class="taking_input">
                        <input matInput placeholder="Enter Email Address" formControlName="email" (input)="onEmailChange($event)" />
                      </mat-form-field>
                    </div>
                    <div class="field">
                      <div class="label">Password<span class="required">*</span></div>
                      <mat-form-field class="taking_input" appearance="outline">
                        <input matInput placeholder="Enter Password" [type]="signinHide ? 'password' : 'text'"
                          formControlName="password" (keyup.enter)="login()" />
                        <mat-icon matSuffix (click)="signinHide = !signinHide" class="eye-icon">{{signinHide ? 'visibility_off' :
                          'visibility'}}</mat-icon>
                      </mat-form-field>
                    </div>
                    <div class="forgot-password" (click)="forgotPasword()">
                      Forgot password
                    </div>
                    <div class="continue_btn">
                        <button (click)="login()" [disabled]="!signInForm.valid" *ngIf="!loader" [ngClass]="{'disable-btn': !signInForm.valid}">
                          Login
                        </button>
                        <button *ngIf="loader">
                            <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                        </button>
                      </div>
                  </div>
            </form>
            <div class="alrea_account">
                No account yet? <span style="cursor: pointer;" (click)="redirectToSignup()">Sign Up</span>
              </div>
        </div>
    </div>
    <div class="section_right_part"> 
      <div class="popup-header">
        <div class="logo">
          <img src="../../../../assets/images/Simpo Logo_2 3 (2).png" alt="Simpo.ai">
        </div>
        <div class="close_icon">
            <div (click)="closePopup()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
      </div>
        <div class="right_section_title">
            Lets build your business with Simpo.ai
        </div>
        <div class="list_features">
            <ng-container *ngFor="let item of textDescArray">
                <div class="single_feature">
                    <div class="feature_image">
                        <img src="./../../../../assets/images/common/success.svg" alt="sucess">
                    </div>
                    <div class="feature_text">
                        <div class="feature_heading">
                            {{item.title}}
                        </div>
                        <div class="feature_description">
                            {{item.description}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>


<div class="delete-popup">
  <div class="modal" id="error" tabindex="-1" aria-labelledby="deletepopupLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="z-index:1001;">
      <div class="modal-content">
        <div class="top-section-modal">
          <img src="../../../../../../assets/images/invoice/delete-invoice.svg" alt class="delete-img">
          <div class="error-text">
            User already exists with given details.
          </div>
        </div>
        <div class="button">
          <button type="button" class="cancel-button" data-bs-dismiss="modal" (click)="closeWindow()">Ok</button>
        </div>
      </div>
      </div>
  </div>
</div>
